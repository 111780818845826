import { useIsMobile } from "@hooks/useIsMobile";
import { Box, Drawer, Link, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/CloseRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import AttachIcon from '@mui/icons-material/AttachFileOutlined';
import { useState } from "react";
import { DateTime } from "luxon";
import PrimaryButton from "@components/buttons/PrimaryButton";
import { ButtonCover } from "@ui/button";
import { useAuth } from "@contexts/AuthContext";
import EditStatusModal from "./edit-status-mobile";
import ScheduleIcon from '@mui/icons-material/ScheduleRounded';
import PlayIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import DndIcon from '@mui/icons-material/DoDisturbRounded';
import CheckCirlceIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import { useNavigate } from "react-router-dom";
import CheckBox from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxChecked from "@mui/icons-material/CheckBoxRounded";
import BasicDatePicker from "@ui/dateInput";
import BasicTimePicker from "@ui/time-picker";
import moment from 'moment-timezone';
import { getRequest, postRequest, putRequest } from "@utils/http.service";
import { FormattedMessage, useIntl } from 'react-intl';
import { getColorByText, getInitials, getTitle } from "@utils/helpers";
import toast from "react-hot-toast";

export default function WorkOrderDetail({wo, onClose, vendors}: any) {

    const intl = useIntl();

    const isMobile = useIsMobile();

    const [activeTab, setActiveTab] = useState(0);

    const {user, isPmRole} = useAuth();

    const [idx, setIdx] = useState(0);

    const [workOrder, setWorkOrder] = useState(wo);

    const [editStatusModal, setEditStatusModal] = useState(false);

    const [showTechs, setShowTechs] = useState(false);

    const navigate = useNavigate();

    const [areas, setAreas] = useState(workOrder.areas ?? []);
    const [category, setCategory] = useState(user?.categories?.find(cat => cat.name === workOrder.category?.split('-')[0].trim())?.id);
    const [issueType, setIssueType] = useState(workOrder.categoryId);
    const [description, setDescription] = useState(workOrder.description);

    const [appt, setAppt] = useState(workOrder.appointment);
    const [apptDate, setApptDate] = useState(new Date().toISOString());
    const [apptTime, setApptTime] = useState(new Date().toISOString());
    const [notes, setNotes] = useState(workOrder.notes);
    const [completedNotes, setCompletedNotes] = useState(workOrder.completedNotes);
    const [holdReason, setHoldReason] = useState(workOrder.holdReason);
    const [cancelReason, setCancelReason] = useState(workOrder.cancellationReason);

    const [selectedTech, setSelectedTech] = useState(workOrder.assignedTo);

    const [gdNote, setGdNote] = useState('');

    const saveData = async(isGeneral = true) => {
        const date1 = moment(apptDate).format().substring(0, 10);
        const time1 = moment(apptTime).format().substring(11, 19);

        const update = isGeneral ? {
            areas: areas, 
            categoryId: issueType, 
            category: user?.categories?.find(cat => cat.id === category).subCategories.find((sC: any) => sC.id == issueType).name,
            description: description
        } : {
            ...(notes && {notes: notes}),
            ...(completedNotes && {completedNotes: completedNotes}),
            ...(holdReason && {holdReason: holdReason}),
            ...(cancelReason && {cancellationReason: cancelReason}),
            ...(workOrder.status == 'Scheduled' && appt && !workOrder.isMakeReady && {appointment: appt}),
            ...(workOrder.status == 'Scheduled' && date1 && {scheduledDate: date1}),
            ...(workOrder.status == 'Scheduled' && appt == 'Call' && time1 && {scheduledTime: time1}),
        };
        await putRequest(`work-orders/${workOrder.id}`, update, {
            headers: {
                Authorization: `Bearer ${user!!.token}`
            }
        });
        setWorkOrder({...workOrder, ...update});
        toast.success(`Update work order ${workOrder.id} successfully.`);
        setEditing('');
    }

    const getTimeSpent = (q = 0) => {
        let timeSpent = 0;
        if (workOrder.status == 'Completed') {
            if(workOrder.alreadySpentTime) {
                timeSpent = workOrder.alreadySpentTime;
            } else if(workOrder.startDate && workOrder.completedDate) {
                timeSpent = moment(new Date(workOrder.completedDate + 'T' + workOrder.completedTime)).diff(
                    moment(new Date(workOrder.startDate + 'T' + workOrder.startTime)),
                    'minutes',
                );
            }
        }
        if (workOrder.status == 'In Progress' && workOrder.startDate) {
            timeSpent = moment().diff(
              moment(new Date(workOrder.startDate + 'T' + workOrder.startTime)),
              'minutes',
            );
            timeSpent += (workOrder.alreadySpentTime ?? 0);
        }
        let spent = ''
        if (timeSpent > 1440) {
            spent += `${Math.floor(timeSpent / 1440)}d `;
            timeSpent = timeSpent % 1440;
        }
        if (timeSpent > 60) {
            spent += `${Math.floor(timeSpent / 60)}h `;
            timeSpent = timeSpent % 60;
        }
        if (timeSpent > 0) {
            spent += `${timeSpent}m `;
        }
        return (spent ? spent :  '0 m') + (q == 0 ? ' on work order' : '');
    }

    const updateTech = async() => {
        const update = {
            assignedTo: selectedTech, 
            assignedToPersonId: user?.techUsers.find(u => u.name == selectedTech).thirdPartyId,
        }
        await putRequest(`work-orders/${workOrder.id}`, update, {
            headers: {
                Authorization: `Bearer ${user!!.token}`
            }
        });
        toast.success(`Update work order ${workOrder.id} successfully.`);
        setWorkOrder({...workOrder, ...update});
        setShowTechs(false);
    }

    const statuses = [
        {name: 'Submitted', color: '#BF4BD2', icon: <ScheduleIcon sx={{fontSize: '32px'}}/>},
        {name: 'Scheduled', color: '#BF4BD2', icon: <ScheduleIcon sx={{fontSize: '32px'}}/>},
        {name: 'In Progress', color: '#3478B7', icon: <PlayIcon sx={{fontSize: '32px'}}/>},
        {name: 'On Hold', color: '#F9703E', icon: <DndIcon sx={{fontSize: '32px'}}/>},
        {name: 'Completed', color: '#4EAA76', icon: <CheckCirlceIcon sx={{fontSize: '32px'}}/>},
        {name: 'Closed', color: '#4EAA76', icon: <CheckCirlceIcon sx={{fontSize: '32px'}}/>},
        {name: 'Cancelled', color: '#EF4E4E', icon: <ClearIcon sx={{fontSize: '32px'}}/>},
    ]

    const statusesPM = [
        {name: 'Submitted', background: '#E9C2F0', color: '#9229A3'},
        {name: 'Scheduled', background: '#E9C2F0', color: '#9229A3'},
        {name: 'In Progress', background: '#CEE6FF', color: '#3478B7'},
        {name: 'On Hold', background: '#FFE8D9', color: '#F9703E'},
        {name: 'Completed', background: '#D6F9E5', color: '#217345'},
        {name: 'Closed', background: '#D6F9E5', color: '#217345'},
        {name: 'Cancelled', background: '#FFE0E0', color: '#AB091E'},
    ]

    const prioritiesPM = [
        {name: 'Urgent', background: '#FFE0E0', color: '#AB091E'},
        {name: 'High', background: '#FFF3C4', color: '#CB6E17'},
        {name: 'Medium', background: '#F0F8FF', color: '#3478B7'},
        {name: 'Low', background: '#FFE8D9', color: '#F9703E'},
        {name: 'No Priority', background: '#F2F6F8', color: '#2B363B'},
    ]

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event: any, idx: number) => {
        setIdx(idx);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = async(event: any) => {
        const update: any = idx === 0 ? {status: event.target.textContent} : {priority: event.target.textContent};
        if(idx === 0) {
            if(event.target.textContent == 'In Progress') {
                const date2 = moment().format().substring(0, 10);
                const time2 = moment().format().substring(11, 19);
                update.startDate = date2;
                update.startTime = time2;
            }
            if(event.target.textContent == 'Completed') {
                const date3 = moment().format().substring(0, 10);
                const time3 = moment().format().substring(11, 19);
                update.completedDate = date3;
                update.completedTime = time3;
            }
        }
        await putRequest(`work-orders/${workOrder.id}`, update, {
            headers: {
                Authorization: `Bearer ${user!!.token}`
            }
        });
        setWorkOrder({...workOrder, ...update});
        toast.success(`Update work order ${workOrder.id} successfully.`);
        handleClose();
    };

    const updateStatus = async(val: any, obj: any, notifyTenant: boolean) => {
        await putRequest(`work-orders/${workOrder.id}?notifyTenant=${notifyTenant}`, {status: val, ...obj}, {
            headers: {
                Authorization: `Bearer ${user!!.token}`
            }
        });
        const update = {status: val, ...obj};
        setWorkOrder({...workOrder, ...update});
        toast.success(`Update work order ${workOrder.id} successfully.`);
    }

    const [editing, setEditing] = useState('');

    const addNote = async() => {
        if(!file) {
            await postRequest(`work-orders/notes/${workOrder.id}`, {note: gdNote, type: 'comment', userId: user?.id}, {
                headers: {
                    Authorization: `Bearer ${user!!.token}`
                }
            });
            setGdNote('');
            const ns = workOrder.gdNotes;
            ns.unshift({note: gdNote, type: 'comment', userId: user?.id, createdAt: new Date()})
            setWorkOrder({...workOrder, gdNotes: ns});
        } else {
            const form = new FormData();
            form.append('note', gdNote);
            form.append('type', 'comment');
            form.append('userId', user!!.id);
            form.append('file', file);
            await postRequest(`work-orders/file-notes/${workOrder.id}`, form, {
                headers: {
                    Authorization: `Bearer ${user!!.token}`
                }
            });
            const resp = await getRequest(`work-orders/${workOrder.id}`, {
                headers: {
                    Authorization: `Bearer ${user!!.token}`
                }
            });
            setWorkOrder(resp.data);
            setGdNote('');
            setFile(null);
        }
    }

    const getUserName = (note: any) => {
        const u = user?.users.find((u) => u.id == note.userId);
        return u?.firstName + ' ' + u?.lastName;
        
    }

    const getDate = (note: any) => {
        return moment(note.createdAt).format('MMM DD');
    }

    const getTime = (note: any) => {
        return moment(note.createdAt).format('hh:mm a');
    }

    const handleFileChange = ({ target }: any) => {
        console.log(target.files)
        if(target.files && target.files.length) {
            setFile(target.files[0]);
        }
    }

    const [file, setFile] = useState(null as any);

    return <>
        <Drawer
            ModalProps={{
            keepMounted: true
            }}
            anchor={'right'}
            sx={{
                flexShrink: 0,
                borderBottomRightRadius: 8,
                [`& .MuiDrawer-paper`]: {
                    height: '100%',
                    width: isMobile ? '100%' : '432px',
                    minWidth: '300px',
                    // top: 'unset',
                    boxSizing: 'border-box',
                    border: 'none',
                    // backgroundColor: palette.primary[10],
                },
                '&.MuiModal-root.MuiDrawer-root': {
                    zIndex: 1300
                }
            }}
            open={true}
            onClose={onClose}
        >
            <Box>
                <Box sx={{paddingY: '1rem', paddingX: '1.5rem', display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{cursor: 'pointer'}} onClick={onClose}><CloseIcon sx={{fontSize: '2rem'}}/></Box>
                    <Box sx={{alignSelf: 'center', alignItems:'center', width: '100%'}}>
                        <Typography sx={{fontSize: '1.25rem', lineHeight: '1.75rem', textAlign: 'center', color: '#5A6A72'}}><FormattedMessage id="Work Order"/> #{workOrder.number}</Typography>
                    </Box>
                </Box>
                <Box sx={{paddingY: '1rem', paddingX: '1.5rem', marginBottom: 12}}>
                    <Typography sx={{fontSize: '1rem', lineHeight: '1.5rem', color: '#5A6A72'}}>{workOrder.category}</Typography>
                    <Typography sx={{fontSize: '1.5rem', lineHeight: '2rem', color: '#303436', paddingTop: '0.5rem'}}>{workOrder.isMakeReady ? getTitle(workOrder.description) : workOrder.description}</Typography>

                    <Tabs variant="fullWidth" value={activeTab} onChange={(e, v) => setActiveTab(v)}>
                        <Tab label={<FormattedMessage id="Details" />}></Tab>
                        <Tab label={<FormattedMessage id="Notes" />}></Tab>
                        {/* <Tab label="Parts"></Tab> */}
                    </Tabs>
                    {activeTab === 0 &&<Box sx={{display:'flex', flexDirection: 'column', gap: '1.5rem', marginTop: '1.25rem'}}>
                        <Box sx={{  paddingY: '1.25rem', display: 'flex', flexDirection: 'column', gap: '1.5rem'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', color:'#57BD83'}}>
                                {!workOrder.isMakeReady && <Typography><FormattedMessage id="General"/></Typography> }
                                {user?.role !== 'tech' && !workOrder.isMakeReady && <Box sx={{display: 'flex', gap: '0.5rem', cursor: 'pointer'}} onClick={() => setEditing('general')}>
                                    <Typography sx={{color: editing == 'general'? '#8D9DA5' : '#57BD83'}}><EditIcon/></Typography>
                                    <Typography sx={{color: editing == 'general' ? '#8D9DA5' : '#57BD83', fontWeight: 500}}>{editing == 'general' ? 'Editing' : 'Edit'}</Typography>
                                </Box>}
                            </Box>
                            {editing != 'general' && <>
                                {!workOrder.isMakeReady && <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography><FormattedMessage id="Area"/></Typography>
                                    <Typography>{workOrder.areas?.join(', ')}</Typography>
                                </Box>}
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography><FormattedMessage id="Category"/></Typography>
                                    <Typography>{workOrder.category?.split('-')[0]}</Typography>
                                </Box>
                                {!workOrder.isMakeReady && <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography><FormattedMessage id="Issue Type"/></Typography>
                                    <Typography>{workOrder.category?.split('-').length ? workOrder.category?.split('-')[1] : ''}</Typography>
                                </Box>}
                                {!workOrder.isMakeReady && <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography><FormattedMessage id="Date Created"/></Typography>
                                    <Typography>{workOrder.reportedDate && DateTime.fromISO(workOrder.reportedDate).toFormat('MM/dd/yyyy')}</Typography>
                                </Box>}
                                {['In Progress', 'Completed'].indexOf(workOrder.status) >= 0 && <>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography><FormattedMessage id="Appointment Date"/></Typography>
                                        {workOrder.scheduledDate && <Typography>{DateTime.fromFormat(workOrder.scheduledDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy')}</Typography>}
                                    </Box>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography><FormattedMessage id="Appointment Time"/></Typography>
                                        <Typography>{workOrder.scheduledTime}</Typography>
                                    </Box>
                                </>}
                                <Box sx={{display: 'flex', gap: '0.75rem', flexDirection: 'column'}}>
                                    <Typography><FormattedMessage id="Description"/></Typography>
                                    <Typography>{workOrder.description}</Typography>
                                </Box>
                                
                            </>}
                            {editing == 'general' && <>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
                                    <Typography><FormattedMessage id="Area"/></Typography>
                                    
                                    <Select multiple value={areas} onChange={(e) => {setAreas(e.target.value);}}>
                                        <MenuItem value={"Bathroom 1"}>Bathroom 1</MenuItem>
                                        <MenuItem value={"Bathroom 2"}>Bathroom 2</MenuItem>
                                        <MenuItem value={"Bedroom 1"}>Bedroom 1</MenuItem>
                                        <MenuItem value={"Bedroom 2"}>Bedroom 2</MenuItem>
                                        <MenuItem value={"Bedroom 3"}>Bedroom 3</MenuItem>
                                        <MenuItem value={"Kitchen"}>Kitchen</MenuItem>
                                        <MenuItem value={"Hallway"}>Hallway</MenuItem>
                                        <MenuItem value={"Living Room"}>Living Room</MenuItem>
                                        <MenuItem value={"Patio"}>Patio</MenuItem>
                                    </Select>

                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
                                    <Typography><FormattedMessage id="Category"/></Typography>
                                    <Select value={category}  onChange={(e) => {setCategory(e.target.value);}}>
                                        {user?.categories?.map(cat => <MenuItem value={cat.id}>{cat.name}</MenuItem>)}
                                    </Select>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
                                    <Typography><FormattedMessage id="Issue Type"/></Typography>
                                    <Select value={issueType} onChange={(e) => {setIssueType(e.target.value);}}>
                                        {user?.categories?.find(cat => cat.id === category)?.subCategories.map((cat: any) => <MenuItem value={cat.id}>{cat.name}</MenuItem>)}
                                    </Select>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
                                    <Typography><FormattedMessage id="Date Created"/></Typography>
                                    <Typography>{workOrder.reportedDate && DateTime.fromISO(workOrder.reportedDate).toFormat('MM/dd/yyyy')}</Typography>
                                </Box>
                                <Box sx={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
                                    <Typography><FormattedMessage id="Description"/></Typography>
                                    <TextField multiline={true}
                                        minRows={4}
                                        maxRows={4}
                                        sx={{
                                            "& input": {
                                              backgroundColor: "white",
                                              borderRadius: ".5rem",
                                            },
                                        }}
                                        value={description}
                                        onChange={(e) => {setDescription(e.target.value);}}
                                    >
                                    </TextField>
                                </Box>
                                <Box sx={{paddingBottom: '1.25rem', display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                                    <ButtonCover onClick={() => setEditing('')} sx={{ color: '#57BD83', padding: '10px 16px', width: '50%', fontSize: '16px', fontWeight: 600, lineHeight: '24px', borderRadius: '8px', border: '1px solid rgba(48, 52, 54, 0.35);'}}>
                                        <Box><FormattedMessage id="Cancel"/></Box>
                                    </ButtonCover>
                                    <ButtonCover onClick={() => saveData()} sx={{backgroundColor: '#57BD83', color: '#fff', padding: '10px 16px', width: '50%', fontSize: '16px', fontWeight: 600, lineHeight: '24px', borderRadius: '8px',}}>
                                        <Box><FormattedMessage id="Save Changes"/></Box>
                                    </ButtonCover>
                                </Box>
                            </>}
                        </Box>
                        <Box sx={{  paddingY: '1.25rem', display: 'flex', flexDirection: 'column', gap: '1.5rem'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', color:'#57BD83'}}>
                                <Typography><FormattedMessage id="Status Info"/></Typography>
                                {(workOrder.status === 'Scheduled' || 
                                    workOrder.status === 'On Hold' ||
                                    workOrder.status === 'Completed' ||
                                    workOrder.status === 'Cancelled') &&<Box sx={{display: 'flex', gap: '0.5rem', cursor: 'pointer'}} onClick={() => setEditing('statusinfo')}>
                                    <Typography sx={{color: editing == 'statusinfo' ? '#8D9DA5' : '#57BD83'}}><EditIcon/></Typography>
                                    <Typography sx={{color: editing == 'statusinfo' ? '#8D9DA5' : '#57BD83', fontWeight: 500}}>{editing == 'statusinfo' ? <FormattedMessage id='Editing' /> : <FormattedMessage id='Edit'/>}</Typography>
                                </Box>}
                            </Box>
                            {workOrder.status === 'Scheduled' && <>
                                {editing == 'statusinfo' ? <>
                                    {!workOrder.isMakeReady && <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 4}}>
                                        <Typography><FormattedMessage id="Appointment Type"/></Typography>
                                        <Select placeholder='Select an Option' fullWidth value={appt} onChange={(e) => {setAppt(e.target.value);}}>
                                            <MenuItem value={"Call"}><FormattedMessage id="Appointment Required" /></MenuItem>
                                            <MenuItem value={"Permission Given"}><FormattedMessage id="Permission to Enter" /></MenuItem>
                                        </Select>
                                    </Box>}
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}>
                                        <Typography><FormattedMessage id="Scheduled Date"/></Typography>
                                        <BasicDatePicker value={DateTime.fromISO(apptDate).toLocal()} onChange={(val: any) => setApptDate(val.toString())} />
                                    </Box>
                                    {appt == 'Call' && <><Box sx={{display: 'flex', justifyContent: 'space-between', gap: 2}}>
                                        <Typography><FormattedMessage id="Scheduled Time"/></Typography>
                                        <BasicTimePicker value={DateTime.fromISO(apptTime).toLocal()} onChange={(value:any) => setApptTime(value.toString())} />
                                    </Box></>}
                                </> : <>
                                    {!workOrder.isMakeReady && <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography><FormattedMessage id="Appointment Type"/></Typography>
                                        <Typography>{workOrder.appointment == 'Call' ? <FormattedMessage id='Appointment Required' /> : workOrder.appointment == 'Permission Given' ? <FormattedMessage id='Permission to Enter' /> : ''}</Typography>
                                    </Box>}
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography><FormattedMessage id="Scheduled Date"/></Typography>
                                        {workOrder.scheduledDate && <Typography>{DateTime.fromFormat(workOrder.scheduledDate, 'yyyy-MM-dd').toFormat('MM/dd/yyyy')}</Typography>}
                                    </Box>
                                    {workOrder.appointment == 'Call' && <><Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography><FormattedMessage id="Scheduled Time"/></Typography>
                                        <Typography>{workOrder.scheduledTime}</Typography>
                                    </Box></>}
                                </>}
                                
                            </>}
                            {workOrder.status === 'In Progress' && <>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography><FormattedMessage id="Time Spent on Work Order" /></Typography>
                                    <Typography>{getTimeSpent(1)}</Typography>
                                </Box>
                            </>}
                            {workOrder.status === 'On Hold' && <>
                                {editing == 'statusinfo' ? <>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                        <Typography><FormattedMessage id="Reason"/></Typography>
                                        <Select placeholder='Select an Option' fullWidth value={holdReason} onChange={(e) => {setHoldReason(e.target.value);}}>
                                            <MenuItem value={"Parts are Needed"}>Parts are Needed</MenuItem>
                                            <MenuItem value={"Vendor Services Required"}>Vendor Services Required</MenuItem>
                                            <MenuItem value={"Other"}>Other</MenuItem>
                                        </Select>
                                    </Box>
                                    <Box sx={{display: 'flex', gap: '0.75rem', flexDirection: 'column'}}>
                                        <Typography><FormattedMessage id="Status Notes"/></Typography>
                                        <TextField multiline minRows={4} maxRows={4} sx={{
                                            "& input": {
                                              backgroundColor: "white",
                                              borderRadius: ".5rem",
                                            },
                                        }}
                                        value={notes}
                                        onChange={(e) => {setNotes(e.target.value);}} />
                                    </Box>
                                </>:<>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography><FormattedMessage id="Reason"/></Typography>
                                        <Typography>{workOrder.holdReason}</Typography>
                                        
                                    </Box>
                                    <Box sx={{display: 'flex', gap: '0.75rem', flexDirection: 'column'}}>
                                        <Typography><FormattedMessage id="Status Notes"/></Typography>
                                        <Typography>{workOrder.notes}</Typography>
                                    </Box>
                                </>}
                            </>}
                            {workOrder.status === 'Completed' && <>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography><FormattedMessage id="Total Time on Work Order"/></Typography>
                                    <Typography>{getTimeSpent(1)}</Typography>
                                </Box>
                                {editing == 'statusinfo' ? 
                                    <Box sx={{display: 'flex', gap: '0.75rem', flexDirection: 'column'}}>
                                        <Typography><FormattedMessage id="Completion Notes"/></Typography>
                                        <TextField multiline minRows={4} maxRows={4} sx={{
                                            "& input": {
                                              backgroundColor: "white",
                                              borderRadius: ".5rem",
                                            },
                                        }}
                                        value={completedNotes}
                                        onChange={(e) => {setCompletedNotes(e.target.value);}} />
                                    </Box> :
                                    <Box sx={{display: 'flex', gap: '0.75rem', flexDirection: 'column'}}>
                                        <Typography><FormattedMessage id="Completion Notes"/></Typography>
                                        <Typography>{workOrder.completedNotes}</Typography>
                                    </Box>
                                }
                            </>}
                            {workOrder.status === 'Cancelled' && <>
                                {editing == 'statusinfo' ? <>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                        <Typography><FormattedMessage id="Reason"/></Typography>
                                        <Select placeholder='Select an Option' fullWidth value={cancelReason} onChange={(e) => {setCancelReason(e.target.value);}}>
                                            <MenuItem value={"Tenant Cancelled"}>Tenant Cancelled</MenuItem>
                                            <MenuItem value={"Duplicate Work Order"}>Duplicate Work Order</MenuItem>
                                            <MenuItem value={"Going to be a Make Ready Item"}>Going to be a Make Ready Item</MenuItem>
                                            <MenuItem value={"Other"}>Other</MenuItem>
                                        </Select>
                                    </Box>
                                    <Box sx={{display: 'flex', gap: '0.75rem', flexDirection: 'column'}}>
                                        <Typography><FormattedMessage id="Status Notes"/></Typography>
                                        <TextField multiline minRows={4} maxRows={4} sx={{
                                            "& input": {
                                              backgroundColor: "white",
                                              borderRadius: ".5rem",
                                            },
                                        }}
                                        value={notes}
                                        onChange={(e) => {setNotes(e.target.value);}} />
                                    </Box>
                                </>:<>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography><FormattedMessage id="Reason"/></Typography>
                                        <Typography>{workOrder.cancellationReason}</Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', gap: '0.75rem', flexDirection: 'column'}}>
                                        <Typography><FormattedMessage id="Status Notes"/></Typography>
                                        <Typography>{workOrder.notes}</Typography>
                                    </Box>
                                </>}
                            </>}
                            {editing == 'statusinfo' && 
                                <Box sx={{paddingBottom: '1.25rem', display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                                    <ButtonCover onClick={() => setEditing('')} sx={{ color: '#57BD83', padding: '10px 16px', width: '50%', fontSize: '16px', fontWeight: 600, lineHeight: '24px', borderRadius: '8px', border: '1px solid rgba(48, 52, 54, 0.35);'}}>
                                        <Box><FormattedMessage id="Cancel"/></Box>
                                    </ButtonCover>
                                    <ButtonCover onClick={() => saveData(false)} sx={{backgroundColor: '#57BD83', color: '#fff', padding: '10px 16px', width: '50%', fontSize: '16px', fontWeight: 600, lineHeight: '24px', borderRadius: '8px',}}>
                                        <Box><FormattedMessage id="Save Changes"/></Box>
                                    </ButtonCover>
                                </Box>
                            }
                        </Box>
                        {!workOrder.isMakeReady && <Box sx={{  paddingY: '1.25rem', display: 'flex', flexDirection: 'column', gap: '1.5rem'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', color:'#57BD83'}}>
                                <Typography><FormattedMessage id="Tenant Information"/></Typography>
                                {/* <Box sx={{display: 'flex', gap: '0.5rem'}}>
                                    <EditIcon/>
                                    <Typography sx={{color:'#57BD83', fontWeight: 500}}>Edit</Typography>
                                </Box> */}
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography><FormattedMessage id="Tenant Name"/></Typography>
                                <Typography>{workOrder.reportedBy}</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography><FormattedMessage id="Unit Number"/></Typography>
                                <Typography>{workOrder.unit}</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography><FormattedMessage id="Phone Number"/></Typography>
                                <Typography>{workOrder.phone && <Link href={`tel:` + '+17372372707,' + (workOrder.phone.indexOf('+1') >= 0 ? workOrder.phone.substring(2) : workOrder.phone) }>{workOrder.phone}</Link>}</Typography>
                            </Box>
                        </Box>}
                        {user?.role !== 'tech' && !showTechs && workOrder.assignedTo && <Box sx={{  paddingY: '1.25rem', display: 'flex', flexDirection: 'column', gap: '1.5rem'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', color:'#57BD83'}}>
                                <Typography><FormattedMessage id="Technician"/></Typography>
                            </Box>
                            <Box sx={{borderRadius: '0.5rem', border: '1px solid rgba(48, 52, 54, 0.35)',  background: '#FFF', paddingY: '1.25rem', paddingX: '1.5rem', display: 'flex', justifyContent: 'space-between'}}>
                                <Box sx={{display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center'}}>
                                    <Box sx={{ width: '48px', height: '48px', border: '1px solid #DF4107', borderRadius: '50%', background: getColorByText(workOrder.assignedTo), color: '#fff', fontWeight: 500, fontSize: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {getInitials(workOrder.assignedTo)}
                                    </Box>
                                    <Box>
                                        <Typography sx={{color:'#2B363B', fontWeight: 500}}>{workOrder.assignedTo}</Typography>
                                        <Typography sx={{color:'#5A6A72', fontSize: '0.75rem'}}>{user?.aJobs[workOrder.assignedTo] ?? 0} work orders assigned</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{alignSelf: 'center'}}>
                                <ButtonCover onClick={() => navigate(`/profile/${workOrder.assignedToPersonId}`)}><Typography sx={{color: '#57BD83', fontWeight: 500, fontSize: '0.875rem', lineHeight: '1.25rem'}}><FormattedMessage id='View Tech' /></Typography></ButtonCover>
                                </Box>
                            </Box>
                        </Box>}
                        {isPmRole() && <>
                            {showTechs && <>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', color:'#57BD83'}}>
                                    <Typography>{workOrder.assignedTo ? 'Update Technician' : 'Assign Technician'}</Typography>
                                </Box>
                                {user?.techUsers.map(tech => 
                                <MenuItem onClick={(e: any) => {setSelectedTech(tech.name)}} sx={{display:'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Typography>{tech.name}</Typography>
                                    {tech.name == selectedTech ? <CheckBoxChecked sx={{color: '#57BD83'}}/>: <CheckBox/> }
                                </MenuItem>)}
                                <Box sx={{paddingBottom: '1.25rem', display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                                    <ButtonCover onClick={() => setShowTechs(false)} sx={{ color: '#57BD83', padding: '10px 16px', width: '50%', fontSize: '16px', fontWeight: 600, lineHeight: '24px', borderRadius: '8px', border: '1px solid rgba(48, 52, 54, 0.35);'}}>
                                        <Box><FormattedMessage id="Cancel"/></Box>
                                    </ButtonCover>
                                    <ButtonCover onClick={() => updateTech()} sx={{backgroundColor: '#57BD83', color: '#fff', padding: '10px 16px', width: '50%', fontSize: '16px', fontWeight: 600, lineHeight: '24px', borderRadius: '8px',}}>
                                        <Box><FormattedMessage id="Save Changes"/></Box>
                                    </ButtonCover>
                                </Box>
                            </>}
                            {!showTechs && <Box sx={{  paddingBottom: '1.25rem', display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                                <ButtonCover onClick={() => setShowTechs(true)} sx={{backgroundColor: 'rgba(87, 189, 131, 0.10)', color: '#57BD83', padding: '10px 16px', width: '50%', fontSize: '16px', fontWeight: 600, lineHeight: '24px'}}>
                                    <Box>{workOrder.assignedTo ? 'Update Technician' : 'Assign Technician'}</Box>
                                </ButtonCover>
                                <ButtonCover onClick={() => setActiveTab(1)} sx={{backgroundColor: 'rgba(87, 189, 131, 0.10)', color: '#57BD83', padding: '10px 16px', width: '50%', fontSize: '16px', fontWeight: 600, lineHeight: '24px'}}>
                                    <Box>Message</Box>
                                </ButtonCover>
                            </Box>}
                        </>}
                    </Box>}
                    {activeTab === 1 &&<Box sx={{display:'flex', flexDirection: 'column', gap: '1.5rem', marginTop: '1.25rem'}}>
                        <Box sx={{  paddingY: '1.25rem', display: 'flex', flexDirection: 'column'}}>
                            <TextField multiline={true}
                                minRows={4}
                                maxRows={4}
                                placeholder={intl.formatMessage({
                                    id: 'Enter tenant notes or messages to the technician here.'
                                })}
                                sx={{
                                    border: `2px solid #F9FAFB`,
                                    width: "100%",
                                    flexGrow: 1,
                                    boxSizing: "border-box",
                                    borderRadius: 3,
                                    backgroundColor: "#F2F6F8",
                                    padding: '20px 16px 12px 16px',
                                    resize: "none",
                                    "&:focus": {
                                    border: `2px solid #454F5B`
                                    },
                                    "&:hover": {
                                    border: `2px solid #F4F6F8`
                                    }
                                }}
                                value={gdNote}
                                onChange={(e) => {setGdNote(e.target.value);}}
                            />
                            <Box sx={{background: '#F2F6F8', display: 'flex', gap: '1rem', justifyContent: 'flex-end', paddingY: '1rem', paddingX: '1rem', alignItems: 'center'}}>
                                {/* <Input type="file" startAdornment={}/> */}
                                {!file && <Box component="label" sx={{cursor: 'pointer'}}><AttachIcon />
                                    <input type="file" accept="image/*; capture=camera" hidden onChange={handleFileChange}/>
                                </Box>}
                                {file && <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
                                    <ClearIcon sx={{fontSize: '20px', cursor:'pointer'}} onClick={() => setFile(null)}/>
                                    <Typography sx={{color: '#5A6A72', fontSize: '14px', maxWidth: '157px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{file.name}</Typography>
                                </Box>}
                                <PrimaryButton onClick={addNote} btnText={<FormattedMessage id="Publish"/>} isSmall={true} />
                            </Box>
                        </Box>
                        {workOrder.gdNotes.map((note: any) => <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                            <Box sx={{border: '1px rgba(48, 52, 54, 0.14) solid'}}></Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
                                <Typography>{getUserName(note)}</Typography>
                                <Box sx={{width: '1px', border: '1px rgba(48, 52, 54, 0.14) solid'}}></Box>
                                <Typography>{getDate(note)}</Typography>
                                <Typography>{getTime(note)}</Typography>
                            </Box>
                            {note.attachment && <Box width={'100%'} component={'object'} data={note.attachment} />}
                            <Box>
                                <Typography>{note.note}</Typography>
                            </Box>
                        </Box>)}
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                            <Box sx={{border: '1px rgba(48, 52, 54, 0.14) solid'}}></Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
                                <Typography><FormattedMessage id="Work Order Notes"/>:</Typography>
                            </Box>
                            <Box>
                                <Typography>{workOrder.notes}</Typography>
                            </Box>
                        </Box>
                    </Box>}
                </Box>
                {<Box sx={{position: 'fixed', bottom: 0, width: '100%', maxWidth: '432px', paddingY: '1rem', paddingX: '1.5rem'}}>
                    <Box onClick={() => setEditStatusModal(true)} sx={{  paddingBottom: '1rem', cursor: 'pointer'}}>
                        <Box sx={{backgroundColor: statuses.find((s) => s.name === workOrder.status)?.color, color: '#fff', padding: '16px', width: '100%', fontSize: '1px', fontWeight: 600, lineHeight: '20px', borderRadius: '32px'}}>
                            <Box sx={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Box sx={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '16px', alignItems: 'center'}}>
                                    {statuses.find((s) => s.name === workOrder.status)?.icon}
                                    <Box>
                                        <Typography sx={{fontSize: '16px', fontWeight: 600, lineHeight: '20px', color: '#fff'}}><FormattedMessage id={workOrder.status} /></Typography>
                                        {workOrder.status == 'Scheduled' && <Typography sx={{fontSize: '14px', lineHeight: '20px', color: 'rgba(255, 255, 255, 0.93)'}}>{workOrder.appointment == 'Call' && workOrder.scheduledDate ? `Appt. on ${DateTime.fromFormat(workOrder.scheduledDate, 'yyyy-MM-dd').toFormat('MM/dd')}` : workOrder.appointment == 'Permission Given' ? <FormattedMessage id='Permission to Enter'/> : ''}</Typography>}
                                        {workOrder.status == 'In Progress' && <Typography sx={{fontSize: '14px', lineHeight: '20px', color: 'rgba(255, 255, 255, 0.93)'}}>{getTimeSpent()}</Typography>}
                                        {workOrder.status == 'On Hold' && <Typography sx={{fontSize: '14px', lineHeight: '20px', color: 'rgba(255, 255, 255, 0.93)'}}>{workOrder.holdReason}</Typography>}
                                        {workOrder.status == 'Completed' && workOrder.completedDate && <Typography sx={{fontSize: '14px', lineHeight: '20px', color: 'rgba(255, 255, 255, 0.93)'}}>Completed {DateTime.fromFormat(workOrder.completedDate, 'yyyy-MM-dd').toFormat('MM.dd')} | {getTimeSpent(1)}</Typography>}
                                        {workOrder.status == 'Cancelled' && <Typography sx={{fontSize: '14px', lineHeight: '20px', color: 'rgba(255, 255, 255, 0.93)'}}>{workOrder.cancellationReason}</Typography>}
                                    </Box>
                                </Box>
                                <ArrowForwardIcon sx={{fontSize: '24px'}}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>}
            </Box>
        </Drawer>
        {editStatusModal && <EditStatusModal wo={workOrder} options={statuses} onClose={(val: any, obj: any, notifyTenant: boolean) => {
            if(val) updateStatus(val, obj, notifyTenant);
            setEditStatusModal(false);
        }} />}
    </>
}
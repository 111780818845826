import SummaryCard from '@components/cards/SummaryCard';
import PageHeader from '@components/PageHeader';
import {
  Box,
  Grid,
  InputBase,
  MenuItem,
  Stack,
  Select,
  styled,
  Typography,
  Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import GraphCard from '@components/cards/GraphCard';
import WorkOrderBreakDownChart from '@components/charts/work-order-breakdown-chart';
import PropertySelector from '@components/inputs/PropertySelect';
import { useIsMobile } from '@hooks/useIsMobile';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Select as CustomSelect } from '@utils/select';
import palette from '@utils/palette';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';
import ServiceTypeBreakDown from '@components/tables/service-type-breakdown';
import { getTime } from '@pages/DashboardPage';
import SelectDrawer from '@components/drawers/SelectDrawer';
import WorkOrderDetail from '@components/workOrder/Detail';
import { getRequest } from '@utils/http.service';
import { FormattedMessage } from 'react-intl';
import { getInitials, getColorByText } from '@utils/helpers';
import DataRowCard from '@components/cards/DataRowCard';

const MobileSelect = styled(InputBase)(({ }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    background: '#9AD8B4'
  },
  '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
    borderRadius: '1rem'
  },
  '& .MuiSvgIcon-root': {
    height: '2rem',
    width: '2rem',
    marginLeft: '.375rem'
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    padding: '.375rem 2rem .375rem .75rem !important',
    fontSize: '.875rem',
    lineHeight: 1.43,
    fontWeight: 600
  },
  '&.MuiInputBase-root .MuiSvgIcon-root': {
    width: '1.25rem',
    height: '1.25rem'
  }
}));


export default function TechMyProfilePage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { user, updateTechList } = useAuth();
  const [pastDays, setPastDays] = useState(30);
  const [jobs, setJobs] = useState<any[]>([]);

  const [summary, setSummary] = useState<{
    workOrder: null | string;
    avgTimeToFinish: null | string;
    callbacks: null | string;
    appointmentsScheduled: null | string;
  }>({
    workOrder: null,
    avgTimeToFinish: null,
    callbacks: null,
    appointmentsScheduled: null
  });
  const [graphsData, setGraphsData] = useState(null as any);
  const [selectedWo, setSelectedWo] = useState<any>(null);

  enum DrawerType {
    DateFilter = 'DateFilter',
    PropertyFilter = 'PropertyFilter'
  }

  const [drawer, setDrawer] = useState<{
    open: boolean,
    type: DrawerType | null
  }>({
    open: false,
    type: null
  });


  async function getGridData() {
    let url = `work-orders?page=0&pageSize=10&propertyId=${user!!.selectedProperty.id}`;
    url += `&assignedToId=${user!!.id}`;
    try {
      const resp = await getRequest(url, {
        headers: {
          Authorization: `Bearer ${user!!.token}`
        }
      });
      const responseData = resp.data.rows;
      setJobs(responseData);
    } catch (err) {
      console.error(err);
    }
  }
  const initData = async () => {
    let url = `dashboard/${user?.selectedProperty.id}?pastDays=${pastDays}&assignedToId=${user?.id}`;
    const resp = await getRequest(url, {
    headers: {
        Authorization: `Bearer ${user!!.token}`
    }
    });
    setSummary({
        workOrder: resp.data.newWos,
        avgTimeToFinish: getTime(resp.data.avgTimeToFinish),
        callbacks: resp.data.callbacks,
        appointmentsScheduled: resp.data.apptScheduled
    });
    let url1 = `dashboard/${user?.selectedProperty.id}/graph?pastDays=${pastDays}&assignedToId=${user?.id}`;
    const resp1 = await getRequest(url1, {
    headers: {
        Authorization: `Bearer ${user!!.token}`
    }
    });
    setGraphsData(resp1.data);
  }

  useEffect(() => {
      initData();
      getGridData();
  }, [pastDays]);

  return (<>
    <Box>
      <PageHeader
        name={getInitials((user?.firstName ?? '').trim() +  ' ' + user?.lastName?.trim())}
        secondTitle={<FormattedMessage id='Technician' />}
        title={(user?.firstName ?? '').trim() +  ' ' + user?.lastName?.trim()}
        selection={(user?.properties?.length || 0) > 1 && <PropertySelector />}
        action={
          <CustomSelect
            value={pastDays}
            sx={{
              '& .MuiInputBase-input': {
                padding: '.675rem .75rem',
                background: palette.white
              }
            }}
            onChange={(e: any, v: any) => {
              setPastDays(e.target.value)
            }}
            IconComponent={ExpandMoreRoundedIcon}
          >
            <MenuItem value={1}><FormattedMessage id='Past 24 Hours' /></MenuItem>
            <MenuItem value={7}><FormattedMessage id='Past 7 Days' /></MenuItem>
            <MenuItem value={14}><FormattedMessage id='Past 14 Days' /></MenuItem>
            <MenuItem value={30}><FormattedMessage id='Past 30 Days' /></MenuItem>
            <MenuItem value={90}><FormattedMessage id='Past 90 Days' /></MenuItem>
          </CustomSelect>
        }
      />
      {isMobile ? (
        <Stack direction="row" spacing={2} sx={{ py: 3 }}>
          <Select
            value={pastDays}
            input={<MobileSelect />}
            IconComponent={ExpandMoreRoundedIcon}
            onChange={(e: any, v: any) => {
              setPastDays(e.target.value)
            }}
          >
            <MenuItem value={1}><FormattedMessage id='Past 24 Hours' /></MenuItem>
            <MenuItem value={7}><FormattedMessage id='Past 7 Days' /></MenuItem>
            <MenuItem value={14}><FormattedMessage id='Past 14 Days' /></MenuItem>
            <MenuItem value={30}><FormattedMessage id='Past 30 Days' /></MenuItem>
            <MenuItem value={90}><FormattedMessage id='Past 90 Days' /></MenuItem>
          </Select>

          {(user?.properties?.length || 0) > 1 && <PropertySelector
            input={<MobileSelect />}
            renderValue={(displayValue) => (value: any) => displayValue}
          />}
        </Stack>
      ) : null}
      <Box sx={{ ...(isMobile ? { mx: -2, px: 2, mt: -2, pt: 2, pb: 2, mb: 3 } : { mb: 5 }) }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <SummaryCard
              isMobile={isMobile}
              title={<FormattedMessage id="New Work Orders" />}
              data={summary.workOrder!!}
              iconBgColor="#EDF1F3"
              cardSx={{
                boxShadow: 'none'
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <SummaryCard
              isMobile={isMobile}
              title={<FormattedMessage id="Avg. Time to Finish" />}
              data={summary.avgTimeToFinish!!}
              iconBgColor="#CEE6FF"
              cardSx={{
                boxShadow: 'none'
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <SummaryCard
              isMobile={isMobile}
              title={<FormattedMessage id="Call Backs" />}
              data={summary.callbacks!!}
              iconBgColor="#FFF3C4"
              cardSx={{
                boxShadow: 'none'
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <SummaryCard
              isMobile={isMobile}
              title={<FormattedMessage id="Appt. Scheduled" />}
              data={summary.appointmentsScheduled!!}
              iconBgColor="#F6DBFA"
              cardSx={{
                boxShadow: 'none'
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 10 }}>
        <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ mb: 5 }}>
          {<FormattedMessage id="Graphs and Charts" />}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <GraphCard title={<FormattedMessage id="Work Order Breakdown" />} sx={{ height: '100%' }}>
              <WorkOrderBreakDownChart graphsData={graphsData}/>
            </GraphCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GraphCard title={<FormattedMessage id="Service Type Breakdown" />} sx={{ height: '100%' }}>
              <ServiceTypeBreakDown graphsData={graphsData}/>
            </GraphCard>
          </Grid>
        </Grid>
      </Box>

      <Stack sx={{ mb: 3 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <FormattedMessage id="Active Jobs"/>
        </Typography>
        <Button onClick={() => {
          navigate('/work-orders');
        }}><Typography variant="body1" sx={{ color: '#57BD83 ' }}><FormattedMessage id="See All" /></Typography></Button>
      </Stack>
      <Grid container spacing={2}>
      {jobs
        // .filter((r) => r.status === 'In Progress')
        .map((row, idx) => (
          <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
            <DataRowCard
              data={row}
              onClick={(type) => {
                setSelectedWo(row);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
    {selectedWo && <WorkOrderDetail wo={selectedWo} onClose={() => {getGridData(); setSelectedWo(null)}}/>}
    {
        isMobile ?
          <>
            <SelectDrawer
              open={drawer.open && drawer.type === DrawerType.PropertyFilter}
              onClose={() => {
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              options={(user || [] as any).properties.map((p: any) => ({ label: p.name, value: p.id }))}
              onChange={(value) => {
                const sp = (user?.properties || []).find((p) => p.id == (value));
                updateTechList(sp);
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              name="property-filter"
              selected={user!!.selectedProperty.id}
              submitBtnLabel='View Property'
              heading="Select Property to View"
            />
          </> : ''
      }
    </>
  );
}

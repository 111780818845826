import PropertySelector from "@components/inputs/PropertySelect";
import PageHeader from "@components/PageHeader";
import { useAuth } from "@contexts/AuthContext";
import { Autocomplete, Box, Button, Checkbox, Collapse, Dialog, DialogContent, IconButton, Paper, Popper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { getCellStyle, getColorByText, getInitials, getTitle, makeReadyTasks, sortMRRows } from "@utils/helpers";
import { getRequest, postRequest, putRequest } from "@utils/http.service";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/AddRounded';
import WorkOrderDetail from "@components/workOrder/Detail";
import { useIsMobile } from "@hooks/useIsMobile";
import palette from "@utils/palette";
import { MobileSelect } from "./DashboardPage";
import { LoaderIcon } from "react-hot-toast";
import { DateTime } from "luxon";
import BasicDatePicker from "@ui/dateInput";
import toast from "react-hot-toast";
import { ButtonCover } from "@ui/button";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { OptionCard } from "@components/drawers/create-workorder/create-wo-type-select";
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { ReactComponent as PersonAddIcon } from '../assets/icons/person-add.svg';
import ModeEditIcon from '@mui/icons-material/ModeEditRounded';
import EditTechModal from "@components/workOrder/edit-tech-mobile";
import SelectDrawer from "@components/drawers/SelectDrawer";
import DataRowCard from "@components/cards/DataRowCard";
import EditVendorModal from "@components/workOrder/edit-vendor-mobile";

function Row(props: any) {
  const { rows, unit, onSetSelectedWo, vendors, techs, updateUnit, updateWorkOrder, setShowCreateWoModal, setSelectedUnit, isPmRole, user } = props;
  const [open, setOpen] = useState(false);
  const workOrders = useMemo(() => sortMRRows(rows), [rows]);
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{border: 0, py:1}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={getCellStyle('unit', unit.unitNumber)}>
          {unit.unitNumber}
        </TableCell>
        <TableCell component="th" scope="row" align="center" sx={getCellStyle('vacancyStatus', unit.vacancyStatus)}>
          {unit.vacancyStatus}
        </TableCell>
        <TableCell component="th" scope="row" align="center" sx={getCellStyle('progress', rows)}>
          {rows.length ? `${rows.filter((w: any) => w.status === 'Completed').length} of ${rows.length} completed` : ''}
        </TableCell>
        <TableCell component="th" scope="row" align="center" sx={getCellStyle('moveOut', unit.moveOut)}>
          {unit.moveOut}
        </TableCell>
        <TableCell component="th" scope="row" align="center" sx={getCellStyle('moveIn', unit.moveIn)}>
          {unit.moveIn}
        </TableCell>
        <TableCell component="th" scope="row" align="center" sx={getCellStyle('dateAvailable', unit.dateAvailable)}>
          {unit.dateAvailable ? 
            calendarOpen ? <BasicDatePicker open={true} onClose={() => setCalendarOpen(false)} onAccept={(dt: any) => {
              updateUnit(unit.id, {dateAvailable: DateTime.fromISO(dt).toFormat('yyyy-MM-dd')})
            }} value={DateTime.fromISO(unit.dateAvailable).toLocal()}  size='small'/> 
            :
            <Typography sx={{fontSize: '16px', gap: '4px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: isPmRole() ? 'pointer' : 'auto',
              color: DateTime.fromISO(unit.dateAvailable).toMillis() < DateTime.now().toMillis() ? 'red' : ''
            }} onClick={() => isPmRole() && setCalendarOpen(true)}>
              {DateTime.fromISO(unit.dateAvailable).toFormat('MM/dd')} {isPmRole() && <CalendarTodayRoundedIcon sx={{fontSize: '16px'}}/>}
            </Typography>
          : <></>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} sx={{border: 0}}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" component="div">
                Make Ready Tasks
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell width={'5%'}>Status</TableCell>
                    <TableCell width={'50%'}>Title</TableCell>
                    <TableCell width={'15%'} align="center">Assignee</TableCell>
                    <TableCell width={'15%'} align="center">Vendor</TableCell>
                    <TableCell width={'15%'} align="center">Scheduled Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workOrders.map((workOrder: any) => (
                    <TableRow key={workOrder.id}>
                      <TableCell scope="row" sx={{border: 0}}>
                        <Checkbox checked={workOrder.status === 'Completed'} disabled={isPmRole() ? false : workOrder.assignedToPersonId !== user?.thirdPartyId ? true : false}
                          onChange={(ev, val) => {
                            if (val) {
                              const confirmed = confirm('Are you sure you want to mark this complete');
                              if (confirmed) {
                                updateWorkOrder(workOrder.id, {status: 'Completed', completedDate: DateTime.now().toFormat('yyyy-MM-dd')}, unit);
                              }
                            }
                          }}></Checkbox>
                      </TableCell>
                      <TableCell scope="row" sx={{
                          border: 0,
                          textDecoration: workOrder.status === 'Completed' ? 'line-through' : '',
                          color: workOrder.scheduledDate ? '' : 'red'
                        }} 
                        onClick={() => {onSetSelectedWo(workOrder)}}>
                        {getTitle(workOrder.description)}
                      </TableCell>
                      <TableCell scope="row" sx={{border: 0}} align="center">
                        {isPmRole() ? <Autocomplete size={'small'} 
                          PopperComponent={(props) => {
                            return <Popper {...props} style={{width: 'fit-content'}}/>
                          }}
                          value={techs.find((t: any) => t.name === workOrder.assignedTo)}
                          onChange={(ev, val) => {
                            if(val){
                              updateWorkOrder(workOrder.id, {assignedTo: val.name, assignedToPersonId: val.thirdPartyId }, unit);
                            } else {
                              updateWorkOrder(workOrder.id, {assignedTo: '', assignedToPersonId: '' }, unit);
                            }
                          }}
                          options={techs}
                          renderInput={(params) => <TextField {...params} />}
                          getOptionLabel={(option: any) => option.name}/> : <>{workOrder.assignedTo}</>}
                      </TableCell>
                      <TableCell scope="row" sx={{border: 0}} align="center">
                        {isPmRole() ? <Autocomplete size={'small'}
                          PopperComponent={(props) => {
                            return <Popper {...props} style={{width: 'fit-content'}}/>
                          }}
                          value={vendors.find((t: any) => t.name === workOrder.vendor)}
                          onChange={(ev, val) => {
                            if(val){
                              updateWorkOrder(workOrder.id, {vendor: val.name}, unit)
                            } else {
                              updateWorkOrder(workOrder.id, {vendor: ''}, unit)
                            }
                          }}
                          options={vendors} 
                          renderInput={(params) => <TextField {...params} />} 
                          getOptionLabel={(option: any) => option.name}
                        /> : <>{workOrder.vendor}</>}
                      </TableCell>
                      <TableCell scope="row" sx={{border: 0}} align="center">
                        {isPmRole() ? <BasicDatePicker onChange={(dt: any) => {
                          updateWorkOrder(workOrder.id, {scheduledDate: DateTime.fromISO(dt).toFormat('yyyy-MM-dd')}, unit);
                        }} value={workOrder.scheduledDate ? DateTime.fromISO(workOrder.scheduledDate).toLocal() : null}  size='small'/>
                      : <>{workOrder.scheduledDate && DateTime.fromISO(workOrder.scheduledDate).toFormat('MM/dd')}</>}
                      </TableCell>
                    </TableRow>
                  ))}
                  {isPmRole() && <TableRow key={`add-wo-${unit.id}`}>
                    <TableCell scope="row" sx={{border: 0}} colSpan={5}>
                      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}} onClick={() => {
                        setShowCreateWoModal(true);
                        setSelectedUnit(unit);
                      }}>
                        <AddIcon sx={{fontSize: '16px'}}/>
                        <Typography sx={{fontSize: '16px', lineHeight: '16px'}}>Add Task</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

function MobileRow(props: any) {
  const { rows, unit, onSetSelectedWo, setEditTechModal, setEditVendorModal,  updateUnit, setShowCreateWoModal, setSelectedUnit, isPmRole } = props;
  const [open, setOpen] = useState(false);
  const workOrders = useMemo(() => sortMRRows(rows), [rows]);
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <Fragment>
      <Box sx={{p:1, background: '#FFFFFF', borderRadius: 2, boxShadow: '0px 6px 24px 0px #3034361A'}}>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} onClick={() => setOpen(!open)}>
          <Typography sx={{fontSize: '16px'}}>{unit.unitNumber}</Typography>
          <Typography sx={{fontSize: '12px'}}>{unit.moveOut ? 'Move Out' : ''}{unit.moveIn && unit.moveOut ? ' - ' : ''}{unit.moveIn ? 'Move In' : ''}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} onClick={() => setOpen(!open)}>
          <Typography sx={getCellStyle('vacancyStatus', unit.vacancyStatus, true)}>{unit.vacancyStatus}</Typography>
          <Typography sx={{fontSize: '12px'}}>
              {unit.moveOut ? DateTime.fromISO(unit.moveOut).toFormat('MM/dd') : ''}{unit.moveOut && unit.moveIn ? ' - ' : ''}
              {unit.moveIn ? DateTime.fromISO(unit.moveIn).toFormat('MM/dd') : ''}
            </Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography sx={getCellStyle('progress', rows, true)} onClick={() => setOpen(!open)}>{rows.length ? `${rows.filter((w: any) => w.status === 'Completed').length} of ${rows.length} completed` : ''}</Typography>
          {unit.dateAvailable && <Typography sx={{fontSize: '12px', gap: '4px', display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => isPmRole() && setCalendarOpen(true)}>
               Available {DateTime.fromISO(unit.dateAvailable).toFormat('MM/dd')} {isPmRole() && <CalendarTodayRoundedIcon sx={{fontSize: '12px'}}/>}
          </Typography>}
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, marginLeft: 1.5}}>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}} onClick={() => {
            setShowCreateWoModal(true);
            setSelectedUnit(unit);
          }}>
            <AddIcon sx={{fontSize: '16px'}}/>
            <Typography sx={{fontSize: '16px', lineHeight: '16px'}}>Add Task</Typography>
          </Box>
          {workOrders.map((workOrder: any) => (
            <DataRowCard data={workOrder} tech={!isPmRole()} onClick={(type) => {
              if (type === 'details') {
                onSetSelectedWo(workOrder);
              } else if (type === 'technician') {
                onSetSelectedWo(workOrder);
                setEditTechModal(true);
              } else if (type === 'vendor') {
                onSetSelectedWo(workOrder);
                setEditVendorModal(true);
              }
            }} />
          ))}
        </Box>
      </Collapse>
      <Dialog open={calendarOpen} onClose={() => setCalendarOpen(false)}>
        <BasicDatePicker open={true} onClose={() => setCalendarOpen(false)} onAccept={(dt: any) => {
          if(dt){
            updateUnit(unit.id, {dateAvailable: DateTime.fromISO(dt).toFormat('yyyy-MM-dd')})
          }
        }} value={DateTime.fromISO(unit.dateAvailable).toLocal()}  size='small'/>
      </Dialog>
    </Fragment>
  );
}

function CreateMRTasks(props: any) {
  const {showCreateWoModal, setShowCreateWoModal, unit, createTasks} = props;
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  return <Dialog open={showCreateWoModal} onClose={() => {setShowCreateWoModal(false)}} maxWidth="md" sx={{
    '& .MuiPaper-root.MuiDialog-paper': {
      borderRadius: '1rem'
    }
  }}>
    <DialogContent sx={{ px: 5, py: 3, borderRadius: '1rem' }}>
      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        <ButtonCover onClick={() => {setShowCreateWoModal(false)}}>
          <ArrowBackIosRoundedIcon sx={{ height: '1rem', width: '1rem', color: '#5a6a72', mr: 1 }} />
          <Typography variant="body1" sx={{ color: '#5a6a72' }} >Back</Typography>
        </ButtonCover>
      </Stack>
      <Typography variant='h5' sx={{ mb: 3 }}>Select Tasks to Create for Unit {unit.unitNumber}</Typography>
      <Stack spacing={2}>
        {makeReadyTasks.map((makeReadyTask: any) => (
          <ButtonCover sx={{ width: '100%', textAlign: 'left' }}
            onClick={() => {
              if(selectedTasks.indexOf(makeReadyTask.name) >= 0) {
                setSelectedTasks(selectedTasks.filter((t) => t !== makeReadyTask.name));
              } else {
                setSelectedTasks([...selectedTasks, makeReadyTask.name])
              }
            }}
          >
            <OptionCard sx={{...(selectedTasks.indexOf(makeReadyTask.name) >= 0 ? {
                background: '#d6f9e5', border: '2px solid #4eaa76'
              } : {'&:hover': {background: '#fff', border: '1px solid rgba(48, 52, 54, 0.35)'}})}}
              title={makeReadyTask.name}
              description={makeReadyTask.description}
            />
          </ButtonCover>
        ))}
        <Button variant="contained" sx={{ width: '100%', p: 1 }} onClick={() => createTasks(selectedTasks)}>
          <Typography sx={{color: '#fff'}}>Create Tasks</Typography>
        </Button>
      </Stack>
    </DialogContent>
  </Dialog>
}

export default function MakeReady() {
  const { user, isPmRole, updateTechList } = useAuth();
  const [workOrders, setWorkOrders] = useState<any[]>([]);
  const [units, setUnits] = useState<any[]>([]);
  const isMobile = useIsMobile();
  const [selectedWo, setSelectedWo] = useState<any>(null);
  const [vendors, setVendors] = useState<any[]>([]);
  const [showCreateWoModal, setShowCreateWoModal] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [editTechModal, setEditTechModal] = useState(false);
  const [editVendorModal, setEditVendorModal] = useState(false);

  const us: any[] = [];
  user!!.techUsers.forEach((u) => {
    us.push({ ...u, name: u.firstName + ' ' + u.lastName.trim() });
  })
  const techOptions = us;

  enum DrawerType {
    PropertyFilter = 'PropertyFilter'
  }

  const [drawer, setDrawer] = useState<{
    open: boolean,
    type: DrawerType | null
  }>({
    open: false,
    type: null
  });

  useEffect(() => {
    getMRWos();
  }, []);


  const getMRWos = async() => {
    const resp = await getRequest(`work-orders?pageSize=2000&propertyId=${user!!.selectedProperty.id}&makeReady=true`, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    const resp1 = await getRequest(`units/${user!!.selectedProperty.id}`, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    const allUnits = resp1.data.filter((u: any) => u.status !== 'Ready' && !u.excludedFromOccupancy)

    const allUnitWithVDates = allUnits.map((u: any) => {
      return {id: u.id, date: (u.dateAvailable || u.moveIn)}
    });

    allUnitWithVDates.sort((u1: any, u2: any) => {
      return u1.date > u2.date ? 1 : -1;
    });

    const finalAllUnits = [];
    for(const ud of allUnitWithVDates) {
      finalAllUnits.push(allUnits.find((u: any) => u.id === ud.id));
    }
    setUnits(finalAllUnits);
    setWorkOrders(resp.data.rows);

    const resp2 = await getRequest(`vendors/${user!!.selectedProperty.id}`, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    const vends = resp2.data.filter((v: any) => v.status === 'Active' && v.compliance === 'Approved')
    vends.sort((v1: any, v2: any) => v1.name > v2.name ? 1 : -1);
    setVendors(vends.filter((v: any) => v.state === user?.selectedProperty.state));
  }

  const updateUnit = async(unitId: any, data: any) => {
    const resp1 = await putRequest(`units/${unitId}`, data, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    getMRWos();
    toast.success(`Updated Unit data successfully.`);
  }

  const updateWorkOrder = async(workOrderId: any, data: any, unit: any) => {
    const resp1 = await putRequest(`work-orders/${workOrderId}`, data, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    await getMRWos();
    toast.success(`Task updated successfully.`);
    if(data.status) {
      checkAndMarkUnitReady(unit, workOrderId);
    }
  }

  const checkAndMarkUnitReady = useCallback((unit: any, workOrderId: any) => {
    const workOrdersForUnit = workOrders.filter((mR: any) => mR.unit === unit.unitNumber);
    if (workOrdersForUnit.length && workOrdersForUnit.length === workOrdersForUnit.filter((w: any) => w.status === 'Completed' || w.id === workOrderId).length) {
      alert('Marking Unit Ready as all make ready tasks are completed');
      updateUnit(unit.id, {status: 'Ready', date: DateTime.now().toFormat('yyyy-MM-dd')})
    }
  }, [workOrders]);

  const createTasks = async(tasks: any[]) => {
    for(const task of tasks) {
      const resp1 = await postRequest(`work-orders`, {
        propertyId: user?.selectedProperty?.id,
        categoryId: user?.categories?.find((c) => c.name === 'Make Ready')?.subCategories[0].id,
        category: 'Make Ready',
        priority: 'Medium',
        isMakeReady: true,
        reportedBy: 'Move Out',
        reportedDate: DateTime.now().toFormat('yyyy-MM-dd'),
        reportedTime: '00:00:00',
        status: 'Submitted',
        unit: selectedUnit.unitNumber,
        description: makeReadyTasks.find((t) => t.name === task)?.description
      }, {
        headers: {
          Authorization: `Bearer ${user!!.token}`
        }
      });
    }
    getMRWos();
    setShowCreateWoModal(false);
    setSelectedUnit(null);
    toast.success(`Tasks created successfully.`);
  }

  return <>
    <Box>
      <PageHeader
        title={'Make Ready Board'}
        selection={(user?.properties?.length || 0) > 1 && <PropertySelector />}
      />
      {isMobile ? (
          <Stack direction="row" spacing={2} sx={{ backgroundColor: palette.white, mx: -2, px: 2, mt: -4, pt: 4, mb: -2, pb: 2 }}>
            <Box onClick={() => {
              setDrawer({
                open: true,
                type: DrawerType.PropertyFilter
              })
            }}>
              { (user?.properties?.length || 0) > 1 ? <PropertySelector
                sx={{
                  pointerEvents: 'none'
                }} input={<MobileSelect />}
                renderValue={(displayValue) => (value: any) => displayValue}
              /> : '' }
            </Box>
          </Stack>
        ) : null}
      {!isMobile ? <TableContainer component={Paper} sx={{borderRadius: '16px', boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)'}}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell width={'4%'}/>
              <TableCell width={'16%'}>Unit</TableCell>
              <TableCell width={'16%'} align="center">Status</TableCell>
              <TableCell width={'16%'} align="center">Progress</TableCell>
              <TableCell width={'16%'} align="center">Move Out Date</TableCell>
              <TableCell width={'16%'} align="center">Move In Date</TableCell>
              <TableCell width={'16%'} align="center">Date Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workOrders.length && units.length ? units.map((unit) => (
              <Row updateWorkOrder={updateWorkOrder}
                updateUnit={updateUnit}
                techs={user?.techUsers}
                key={unit.id}
                vendors={vendors}
                rows={workOrders.filter((mR: any) => mR.unit === unit.unitNumber)}
                unit={unit}
                onSetSelectedWo={(wo: any) => setSelectedWo(wo)}
                setShowCreateWoModal={setShowCreateWoModal}
                setSelectedUnit={setSelectedUnit}
                isPmRole={isPmRole}
                user={user}
              />
            )) : 
              <TableCell colSpan={7}>
                <Box sx={{display: 'flex', minHeight: '200px', alignItems: 'center', justifyContent: 'center' }}><LoaderIcon/></Box>
              </TableCell>
            }
          </TableBody>
        </Table>
      </TableContainer> : <Box sx={{display: 'flex', gap: 2, flexDirection: 'column', marginTop: 3}}>
        {workOrders.length && units.length ? units.map((unit) => (
          <MobileRow 
            updateWorkOrder={updateWorkOrder}
            updateUnit={updateUnit}
            techs={user?.techUsers}
            key={unit.id}
            vendors={vendors}
            rows={workOrders.filter((mR: any) => mR.unit === unit.unitNumber)}
            unit={unit}
            onSetSelectedWo={(wo: any) => setSelectedWo(wo)}
            setShowCreateWoModal={setShowCreateWoModal}
            setSelectedUnit={setSelectedUnit}
            setEditTechModal={setEditTechModal}
            setEditVendorModal={setEditVendorModal}
            isPmRole={isPmRole}
          />
        )) : <Box sx={{display: 'flex', minHeight: '200px', alignItems: 'center', justifyContent: 'center' }}><LoaderIcon/></Box>}
      </Box>
      }
    </Box>
    {selectedWo && <WorkOrderDetail wo={selectedWo} vendors={vendors} onClose={() => {setSelectedWo(null); getMRWos();}} />}
    {selectedUnit && showCreateWoModal && <CreateMRTasks 
      showCreateWoModal={showCreateWoModal} 
      setShowCreateWoModal={setShowCreateWoModal}
      unit={selectedUnit}
      createTasks={createTasks}
    />}
    {selectedWo && editTechModal && <EditTechModal wo={selectedWo} options={techOptions} onClose={() => {setSelectedWo(null); setEditTechModal(false); getMRWos();}} />}
    {selectedWo && editVendorModal && <EditVendorModal wo={selectedWo} options={vendors} onClose={() => {setSelectedWo(null); setEditVendorModal(false); getMRWos();}} />}
    {isMobile ? <>
      <SelectDrawer
        open={drawer.open && drawer.type === DrawerType.PropertyFilter}
        onClose={() => {
          setDrawer({
            open: false,
            type: null
          })
        }}
        options={(user || [] as any).properties.map((p: any) => ({ label: p.name, value: p.id }))}
        onChange={(value) => {
          const sp = (user?.properties || []).find((p) => p.id == (value));
          updateTechList(sp);
          setDrawer({
            open: false,
            type: null
          })
        }}
        name="property-filter"
        selected={user!!.selectedProperty.id}
        submitBtnLabel='View Property'
        heading="Select Property to View"
      />
    </> : ''}
  </>
}
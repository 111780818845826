import { useIsMobile } from '@hooks/useIsMobile';
import { ReactNode, createContext, useContext, useState } from 'react';

export interface IAppContext {
  isNavOpen: boolean;
  setIsNavOpen: (status: boolean) => void;
}

const DefaultValues: IAppContext = {
  isNavOpen: false,
  setIsNavOpen: () => null
};

const AppContext = createContext<IAppContext>(DefaultValues);

export function useApp() {
  return useContext(AppContext);
}

interface IProps {
  children: ReactNode;
}

export function AxiosProvider({ children }: IProps) {
  const isMobile = useIsMobile();
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  const value = {
    isNavOpen,
    setIsNavOpen
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

import { useEffect, useState } from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@utils/theme';
import RootRouter from '@routers/RootRouter';
import { useAuth } from '@contexts/AuthContext';
import { IntlProvider } from 'react-intl';
import { Toaster } from 'react-hot-toast';

export default function App() {
  const queryClient = new QueryClient();
  const { user } = useAuth();

  const [messages, setMessages] = useState(null as any);
  const [userLang, setUserLang] = useState('');

  useEffect(() => {
    if(user && user.lang){
      setUserLang(user.lang);
    }
  }, [user]);

  useEffect(() => {
    let lang = user?.lang;
    if(!lang) {
      if(navigator && navigator.language) {
        if(navigator.language.indexOf('es') >= 0) {
          lang = 'es';
        } else if(navigator.language.indexOf('en') >= 0) {
          lang = 'en';
        }
      }
    }
    setUserLang(lang ?? 'en');
  }, []);

  useEffect(() => {
    let lang = userLang;
    if(lang) {
      const url = `https://api.i18nexus.com/project_resources/translations/${lang}/default.json?api_key=d9nRBy0103h06a7MuybLug`;
      
      fetch(url)
        .then(response => response.json())
        .then(data => {
          setMessages(data);
        });
    }
  }, [userLang]);

  return (
    <IntlProvider locale={user?.lang ?? 'en'} messages={messages}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RootRouter />
          <Toaster position="top-center" />
        </ThemeProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}

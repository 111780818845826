import TextInput from '@components/inputs/TextInput';
import PageHeader from '@components/PageHeader';
import { Box, Grid, styled, Card as MuiCard, Typography, Stack, Button, Switch, MenuItem, Select, Link } from '@mui/material';
import palette from '@utils/palette';
import { Controller, useForm } from 'react-hook-form';
import { useIsMobile } from '@hooks/useIsMobile';
import { useAuth } from '@contexts/AuthContext';
import PrimaryButton from '@components/buttons/PrimaryButton';
import { postRequest } from '@utils/http.service';
import { FormSelectField } from '@ui/form';
import { FormattedMessage } from 'react-intl';

const Card = styled(MuiCard)(({ }) => ({
  borderRadius: '1rem',
  boxShadow: '0px 6px 24px 0px rgba(48, 52, 54, 0.10)',
  backgroundColor: palette.white,
  padding: '1.5rem 1.5rem'
}));
export default function SettingsPage() {
  const isMobile = useIsMobile();
  const {user, updateProfile} = useAuth();
  const { control, getValues, reset, formState } = useForm({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      role: user?.role === 'admin' ? 'Admin' : user?.role === 'pm' ? 'Property Manager' : user?.role === 'supervisor' ? 'Maintenance Supervisor' : 'Technician',
      lang: user?.lang ?? 'en'
    }
  });

  const { control: control1 , getValues: getValues1, reset: reset1, formState: formState1, setError } = useForm({});

  const onSave = async() => {
    const data = getValues();
    await postRequest(`users`, {username: data.email, firstName: data.firstName, lastName: data.lastName, lang: data.lang ?? 'en'}, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    updateProfile();
  }

  const onUpdatePassword = async() => {
    const data = getValues1();
    if(data.newPassword != data.confirmPassword) {
      setError('confirmPassword', {message: 'Passwords should match'})
      return;
    }
    await postRequest(`auth/change-password`, {password: data.currentPassword, newPassword: data.newPassword}, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
  }

  return (
    <>
      <PageHeader title={<FormattedMessage id="Settings"/>} action={<></>} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            <Card>
              <Typography variant="h5" sx={{ fontWeight: 500, color: '#5A6A72' }}>Profile</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    name="firstName"
                    label={<FormattedMessage id="First Name"/>}
                    labelSx={{
                      color: '#303436bf'
                    }}
                    placeholder="Enter Your First Name"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'First Name is required'
                      }
                    }}
                    isSubmitFocus={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    labelSx={{
                      color: '#303436bf'
                    }}
                    name="lastName"
                    label={<FormattedMessage id="Last Name"/>}
                    placeholder="Enter Your Last Name"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Last Name is required'
                      }
                    }}
                    isSubmitFocus={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    labelSx={{
                      color: '#303436bf'
                    }}
                    name="email"
                    label={<FormattedMessage id="Email"/>}
                    placeholder="Enter Your Email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Email is required'
                      }
                    }}
                    isSubmitFocus={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    disabled={true}
                    labelSx={{
                      color: '#303436bf'
                    }}
                    sx={{
                      '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled': {
                        backgroundColor: '#F2F6F8'
                      }
                    }}
                    name="role"
                    label={<FormattedMessage id="Role"/>}
                    placeholder="Enter Your Role"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Role is required'
                      }
                    }}
                    isSubmitFocus={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="lang"
                    control={control}
                    rules={{
                        required: "Language is required.",
                    }}
                    render={({ field }) => {
                      return (
                        <FormSelectField
                          label={<FormattedMessage id="Language"/>}
                          {...field}
                        >
                          <MenuItem value={"en"}>English</MenuItem>
                          <MenuItem value={"es"}>Spanish</MenuItem>
                        </FormSelectField>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Stack direction={ isMobile ? 'column' : 'row'} sx={{ mt: 4 }} spacing={3} justifyContent="flex-end">
                <Button fullWidth={isMobile} onClick={() => reset()} sx={{
                  py: 10 / 8,
                  px: 2,
                  borderRadius: 1,
                  border: '1px solid rgba(48, 52, 54, 0.35)'
                }}><FormattedMessage id="Discard Changes"/></Button>
                <PrimaryButton onClick={onSave}
                  btnText={<FormattedMessage id="Save Changes"/>}
                  formState={formState}
                />
              </Stack>
            </Card>
            <Card>
              <Typography variant="h5" sx={{ fontWeight: 500, mb: 4, color: '#5A6A72' }}>{<FormattedMessage id="Notifications" />}</Typography>
              <Stack direction="row" alignItems="center">
                <Box>
                  <Typography variant="h5" sx={{ mb: .5 }}><FormattedMessage id="Email Notifications"/></Typography>
                  <Typography variant="body1" sx={{ mb: .5, color: '#5A6A72' }}><FormattedMessage id="Send me emails relating to work order statuses, messages, and updates"/></Typography>
                </Box>
                <Switch />
              </Stack>
            </Card>
          </Stack>

        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            <Card>
              <Stack spacing={3}>
                <Typography variant="h5" sx={{ fontWeight: 500, color: '#5A6A72' }}><FormattedMessage id="Change Password"/></Typography>
                <TextInput
                  labelSx={{
                    color: '#303436bf'
                  }}
                  name="currentPassword"
                  label={<FormattedMessage id="Current Password"/>}
                  placeholder="Enter Your Current Password"
                  control={control1}
                  type='password'
                  rules={{
                    required: {
                      value: true,
                      message: 'Current Password is required'
                    }
                  }}
                  isSubmitFocus={true}
                />
                <TextInput
                  labelSx={{
                    color: '#303436bf'
                  }}
                  name="newPassword"
                  label={<FormattedMessage id="New Password"/>}
                  placeholder="Enter Your New Password"
                  control={control1}
                  type='password'
                  rules={{
                    required: {
                      value: true,
                      message: 'New Password is required'
                    }
                  }}
                  isSubmitFocus={true}
                />
                <TextInput
                  labelSx={{
                    color: '#303436bf'
                  }}
                  name="confirmPassword"
                  label={<FormattedMessage id="Confirm New Password"/>}
                  placeholder="Re-Enter Password"
                  type='password'
                  control={control1}
                  rules={{
                    required: {
                      value: true,
                      message: 'Confirm Password is required'
                    }
                  }}
                  isSubmitFocus={true}
                />
                <PrimaryButton onClick={onUpdatePassword}
                  btnText={<FormattedMessage id="Update Password"/>}
                  formState={formState}
                  isFullWidth
                />
              </Stack>


            </Card>
            <Card>
              <Typography variant="h5" sx={{ fontWeight: 500, mb: 4, color: '#5A6A72' }}><FormattedMessage id="Help and Support"/></Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="body1"><FormattedMessage id="Contact"/>:</Typography>
                <Link href='mailto:support@getdone.com' sx={{ ml: .5 }}>support@getdone.com</Link>
              </Stack>
            </Card>
          </Stack>

        </Grid>
      </Grid >
    </>
  );
}

import SummaryCard from '@components/cards/SummaryCard';
import {
    Box,
    Grid,
    InputBase,
    MenuItem,
    Stack,
    Select,
    styled,
    Typography,
    IconButton,
    SwipeableDrawer,
    drawerClasses,
} from '@mui/material';
import { useEffect, useState } from 'react';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import GraphCard from '@components/cards/GraphCard';
import WorkOrderBreakDownChart from '@components/charts/work-order-breakdown-chart';
import ServiceTypeBreakDown from '@components/tables/service-type-breakdown';
import { useIsMobile } from '@hooks/useIsMobile';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Select as CustomSelect } from '@utils/select';
import palette from '@utils/palette';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CloseIcon from '@mui/icons-material/Close';
import PrimaryButton from '@components/buttons/PrimaryButton';
import TechSelector from '@components/inputs/TechSelector';
import WorkOrderGrid from '@components/workOrder/WorkOrderGrid';
import { getInitials } from '@utils/helpers';
import {
    Chip,
    TextField,
    Pagination,
    PaginationItem,
    AppBar,
    Toolbar
} from '@mui/material';
import {
    GridCellModes,
    GridCellModesModel,
    GridCellParams,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowParams,
    GridRowsProp,
    GridSortModel,
    GridValueFormatterParams
} from '@mui/x-data-grid';
import { PRIORITY_TYPE, WORK_TYPE } from '@utils/models';
import { useCallback } from 'react';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import FlagIcon from '@mui/icons-material/Flag';
import ActionCard from '@components/cards/ActionCard';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { getPriorityColor, getColorByText } from '@utils/helpers';
import { ReactComponent as PersonAddIcon } from '../assets/icons/person-add.svg';
import GridEditInput from '@components/workOrder/GridEditInput';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import GridSearch from '@components/workOrder/GridSearch';
import GridFilter from '@components/workOrder/GridFilter';
import DataRowCard from '@components/cards/DataRowCard';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import Link from '@components/Link';
import WorkOrderDetail from '@components/workOrder/Detail';
import PageHeader from '@components/PageHeader';
import { getTime } from './DashboardPage';
import EditPriorityModal from '@components/workOrder/edit-priority-mobile';
import EditTechModal from '@components/workOrder/edit-tech-mobile';
import { getRequest, putRequest } from '@utils/http.service';
import { FormattedMessage } from 'react-intl';
import toast from 'react-hot-toast';

function createData(
    number: string,
    description: string,
    category: string[],
    unit: string,
    appointment: string,
    priority: string,
    assignedTo: string,
    reportedDate: string,
    status: string,
    other: object
) {
    return {
        id: number,
        number,
        description,
        category,
        unit,
        appointment,
        priority,
        assignedTo,
        reportedDate,
        status,
        other
    };
}

interface ISearchInput {
    search?: string;
    status?: any[];
    technician?: any[];
    priority?: any[];
    serviceType?: any[];
    date?: {
        from: DateTime;
        to: DateTime;
    };
    unit?: any[];
}

const SwipeableDrawerComp = styled(SwipeableDrawer)(() => {
    return {
        [`&.${drawerClasses.root}`]: {
            zIndex: 1500
        },
        [`&.${drawerClasses.root} .${drawerClasses.paper}`]: {
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24
        }
    };
});

enum DrawerType {
    DateFilter = 'DateFilter',
    PropertyFilter = 'PropertyFilter'
}

const MobileSelect = styled(InputBase)(({ }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 8,
        background: '#9AD8B4'
    },
    '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
        borderRadius: '1rem'
    },
    '& .MuiSvgIcon-root': {
        height: '2rem',
        width: '2rem',
        marginLeft: '.375rem'
    },
    '& .MuiSelect-select.MuiInputBase-input': {
        padding: '.375rem 2rem .375rem .75rem !important',
        fontSize: '.875rem',
        lineHeight: 1.43,
        fontWeight: 600
    },
    '&.MuiInputBase-root .MuiSvgIcon-root': {
        width: '1.25rem',
        height: '1.25rem'
    }
}));

export default function TechProfilePage1() {
    const [summary, setSummary] = useState<{
        workOrder: null | string;
        avgTimeToFinish: null | string;
        callbacks: null | string;
        appointmentsScheduled: null | string;
    }>({
        workOrder: null,
        avgTimeToFinish: null,
        callbacks: null,
        appointmentsScheduled: null
    });
    const [pastDays, setPastDays] = useState(30);

    const [graphsData, setGraphsData] = useState(null as any);

    const { id } = useParams();

    const [drawer, setDrawer] = useState<{
        open: boolean,
        type: DrawerType | null
    }>({
        open: false,
        type: null
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const [selectedWo, setSelectedWo] = useState<any>(null);
    const { user } = useAuth();
    const [selectedTech, setSelectedTech] = useState<any | null>(user?.techUsers.find(t => t.id == id));
    const [editPriorityModal, setEditPriorityModal] = useState(false);
    const [editTechModal, setEditTechModal] = useState(false);
    

    useEffect(() => {
        setSelectedTech(user?.techUsers.find(t => t.id == id));
    }, [id]);

    // Filter
    const [valueTab, setValueTab] = useState(0);
    const statusOptions = [
        { id: 0, name: 'Not Started' },
        { id: 1, name: 'In Progress' },
        { id: 2, name: 'On Hold' },
        { id: 3, name: 'Completed' }
    ];

    // Search
    const [isDirty, setIsDirty] = useState(false);
    const [searchVisibility, setSearchVisibility] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const toggleDrawer = (open: boolean) => {
        setSearchOpen(open);
    };

    const { control, watch, reset, formState, getFieldState, getValues } =
        useForm<ISearchInput>({
            defaultValues: {
                search: '',
                status: [],
                technician: [],
                priority: [],
                serviceType: [],
                date: {
                    from: undefined,
                    to: DateTime.now()
                },
                unit: []
            },
            mode: 'onChange'
        });
    const us: any[] = [];
    user!!.techUsers.forEach((u) => {
        us.push({ ...u, name: u.firstName + ' ' + u.lastName.trim() });
    })
    const techOptions = us;
    const unitOptions = [
        { id: '123456', name: 'Unit 123' },
        { id: '123457', name: 'Unit 125' },
        { id: '123458', name: 'Unit 126' },
        { id: '123459', name: 'Unit 127' },
        { id: '123451', name: 'Unit 128' },
        { id: '123452', name: 'Unit 129' },
        { id: '123453', name: 'Unit 131' },
        { id: '123454', name: 'Unit 132' },
        { id: '123455', name: 'Unit 133' }
    ];
    const priorityOptions = [
        {
            id: '1',
            name: PRIORITY_TYPE.URGENT,
            description:
                'Issue is creating a hazard to the property and/or to the tenant’s life.',
            color: getPriorityColor(PRIORITY_TYPE.URGENT),
            icon: <FlagIcon />
        },
        {
            id: '2',
            name: PRIORITY_TYPE.HIGH,
            description:
                'Resolve promptly to prevent inconvenience for the tenant and avoid escalation.',
            color: getPriorityColor(PRIORITY_TYPE.HIGH),
            icon: <FlagIcon />
        },
        {
            id: '3',
            name: PRIORITY_TYPE.MEDIUM,
            description:
                'If neglected, issue has the potential to affect the tenants life.',
            color: getPriorityColor(PRIORITY_TYPE.MEDIUM),
            icon: <FlagIcon />
        },
        {
            id: '4',
            name: PRIORITY_TYPE.LOW,
            description: 'Non-urgent matters or routine maintenance.',
            color: getPriorityColor(PRIORITY_TYPE.LOW),
            icon: <FlagIcon />
        },
        {
            id: '5',
            name: PRIORITY_TYPE.NO,
            description: '',
            color: getPriorityColor(PRIORITY_TYPE.NO),
            icon: <OutlinedFlagIcon />
        }
    ];
    
    const serviceTypeOptions = user?.categories;

    // Grid
    const [isStarted, setIsStarted] = useState(true);
    const defaultSort = { column: 'receivedDate', direction: -1 };
    const [sortModel, setSortModel] = useState<GridSortModel>([
        { field: 'receivedDate', sort: 'desc' }
    ]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0
    });
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [total, setTotal] = useState(0);
    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
    const onRowClick = (params: GridRowParams) => {
        setSelectedWo(params.row.other);
    };

    const onCellClick = (params: GridCellParams, event: React.MouseEvent) => {
        switch (params.field) {
            case 'category':
            case 'priority':
            case 'assignedTo':
            case '__check__':
                handleCellClick(params, event);
                break;
            default:
                setSelectedWo(params.row.other);
                break;
        }
    };
    const handleCellClick = useCallback(
        (params: GridCellParams, event: React.MouseEvent) => {
            if (!params.isEditable) {
                return;
            }

            // Ignore portal
            if (!event.currentTarget.contains(event.target as Element)) {
                return;
            }

            setCellModesModel((prevModel) => {
                return {
                    // Revert the mode of the other cells from other rows
                    ...Object.keys(prevModel).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: Object.keys(prevModel[id]).reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field]: { mode: GridCellModes.View }
                                }),
                                {}
                            )
                        }),
                        {}
                    ),
                    [params.id]: {
                        // Revert the mode of other cells in the same row
                        ...Object.keys(prevModel[params.id] || {}).reduce(
                            (acc, field) => ({
                                ...acc,
                                [field]: { mode: GridCellModes.View }
                            }),
                            {}
                        ),
                        [params.field]: { mode: GridCellModes.Edit }
                    }
                };
            });
        },
        []
    );

    function getSortFieldMap(field: string): string {
        switch (field) {
            case 'receivedDate':
                return 'receivedDate';
            default:
                return 'receivedDate';
        }
    }

    function getFilter() {
        let url = `work-orders?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}&propertyId=${user!!.selectedProperty.id}&assignedToId=${id}`;
        const status =
            valueTab === 0
                    ? 'Submitted'
                    : valueTab === 1
                        ? 'In Progress'
                        : valueTab === 2 ? 'On Hold' : 'Completed';
        url += `&status=${status}`;
        url += `&isAssigned=true`;
        if (valueTab === 0) {
            url += `&status=Scheduled`;
        }
        const filtersSelected = getValues();
        if(filtersSelected.serviceType && filtersSelected.serviceType.length > 0) {
        filtersSelected.serviceType.forEach((p) => {
            url += `&category=${p.name}`
        })
        }
        if(filtersSelected.priority && filtersSelected.priority.length > 0) {
        filtersSelected.priority.forEach((p) => {
            url += `&priority=${p.name}`
        })
        }
        if(filtersSelected.technician && filtersSelected.technician.length > 0) {
        filtersSelected.technician.forEach((p) => {
            url += `&assignedToId=${p.id}`
        })
        }
        if(filtersSelected.search) {
        url += `&q=${filtersSelected.search}`
        }
        return url;
    }

    async function onFilterSelected(params: any) {
        getGridData();
    }

    async function getGridData() {
        setTotal(0);
        setIsLoading(true);
        let url = getFilter();
        try {
            const resp = await getRequest(url, {
                headers: {
                    Authorization: `Bearer ${user!!.token}`
                }
            });
            setTotal(resp.data.count);
            const responseData = resp.data.rows;
            const rowData = responseData.map((row: any) => {
                const {
                    number,
                    description,
                    category,
                    unit,
                    appointment,
                    priority,
                    assignedTo,
                    reportedDate,
                    status
                } = row;

                const dateReceivedFormatted = reportedDate
                    ? DateTime.fromISO(reportedDate.toString()).toFormat('MM/dd/yyyy')
                    : '';
                return createData(
                    number,
                    description,
                    category,
                    unit,
                    appointment,
                    priority,
                    assignedTo,
                    dateReceivedFormatted,
                    status,
                    row
                );
            });

            setRows(rowData);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    }

    function getTypeChip(type: string) {
        switch (type) {
            case WORK_TYPE.PLUMING:
                return (
                    <Chip label={type} sx={{ bgcolor: '#CEE6FF', color: '#3478B7' }} />
                );
            case WORK_TYPE.HVAC:
                return (
                    <Chip label={type} sx={{ bgcolor: '#D6F9E5', color: '#4EAA76' }} />
                );
            case WORK_TYPE.ELECTRIC:
                return (
                    <Chip label={type} sx={{ bgcolor: '#FFF3C4', color: '#CB6E17' }} />
                );
            case WORK_TYPE.INTERIOR:
                return (
                    <Chip label={type} sx={{ bgcolor: '#FFD0B5', color: '#F9703E' }} />
                );
            case WORK_TYPE.APPLIANCE:
                return (
                    <Chip label={type} sx={{ bgcolor: '#FBCEFF', color: '#B734A2' }} />
                );
            case WORK_TYPE.HAZARD:
                return (
                    <Chip label={type} sx={{ bgcolor: '#FFBDBD', color: '#AB091E' }} />
                );
            case WORK_TYPE.PREVENT:
                return (
                    <Chip label={type} sx={{ bgcolor: '#EDF1F3', color: '#2B363B' }} />
                );
            default:
                return <Chip label={type} />;
        }
    }

    function getPriority(priority: PRIORITY_TYPE) {
        return (
            <Box color={getPriorityColor(priority)}>
                <FlagIcon />
            </Box>
        );
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', hideable: true },
        {
            field: 'description',
            headerName: 'Work Order Issue',
            flex: 1,
            minWidth: 280,
            sortable: false,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" fontWeight={500} color={'#5A6A72'}>
                    {params.colDef.headerName}
                </Typography>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '14px'
                    }}
                    title={`#${params.row.number} | ${params.value}`}
                >
                    <span>#{params.row.number}</span>
                    <span> | </span>
                    <span>{params.value}</span>
                </span>
            )
        },
        {
            field: 'category',
            headerName: 'Type',
            flex: 1,
            minWidth: 120,
            sortable: false,
            editable: true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" color={'#5A6A72'}>
                    {params.colDef.headerName}
                </Typography>
            ),
            renderCell: (params: GridRenderCellParams<string[]>) => (
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '16px'
                    }}
                    title={params.value}
                >
                    {getTypeChip(params.value)}
                </span>
            ),
            renderEditCell: (params: GridRenderEditCellParams) => {
                return (
                    <GridEditInput editParams={params} options={serviceTypeOptions!!} />
                );
            }
        },
        {
            field: 'unit',
            headerName: 'Unit/Apt',
            flex: 1,
            minWidth: 50,
            sortable: true,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" color={'#5A6A72'}>
                    {params.colDef.headerName}
                </Typography>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '16px'
                    }}
                    title={params.value}
                >
                    {params.value}
                </span>
            )
        },
        {
            field: 'appointment',
            headerName: 'Appointment',
            flex: 1,
            minWidth: 140,
            sortable: true,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" color={'#5A6A72'}>
                    {params.colDef.headerName}
                </Typography>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '16px'
                    }}
                    title={params.value}
                >
                    {params.value}
                </span>
            )
        },
        {
            field: 'priority',
            headerName: 'Priority',
            flex: 1,
            minWidth: 20,
            sortable: false,
            editable: true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" color={'#5A6A72'}>
                    {params.colDef.headerName}
                </Typography>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '16px'
                    }}
                    title={params.value}
                >
                    {getPriority(params.value)}
                </span>
            ),
            renderEditCell: (params: GridRenderEditCellParams) => {
                return (
                    <GridEditInput
                        editParams={params}
                        options={priorityOptions}
                        extraWidth={50}
                    />
                );
            }
        },
        {
            field: 'assignedTo',
            headerName: 'Technician',
            flex: 1,
            minWidth: 200,
            sortable: false,
            editable: true,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" color={'#5A6A72'}>
                    {params.colDef.headerName}
                </Typography>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '16px'
                    }}
                    title={params.value}
                >
                    {params.value && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    lineHeight: '24px',
                                    border: '1px solid #00000014',
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    background: getColorByText(params.value || ''),
                                    color: '#fff',
                                    fontSize: '12px'
                                }}
                            >
                                {getInitials(params.value)}
                            </Box>
                            <Box pl={1}>{params.value}</Box>
                        </Box>
                    )}
                    {!params.value && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <PersonAddIcon />
                            <Typography variant="body2" fontWeight={500} pl={1}>
                                Add Technician
                            </Typography>
                        </Box>
                    )}
                </span>
            ),
            renderEditCell: (params: GridRenderEditCellParams) => {
                return <GridEditInput editParams={params} options={techOptions} />;
            }
        },
        {
            field: 'reportedDate',
            headerName: 'Date Received',
            description: 'This column has a value getter and is not sortable.',
            flex: 1,
            minWidth: 120,
            sortable: true,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => (
                <Typography variant="body1" color={'#5A6A72'}>
                    {params.colDef.headerName}
                </Typography>
            ),
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                return params?.value
                    ? DateTime.fromFormat(params?.value, 'MM/dd/yyyy')
                        .toLocal()
                        .toFormat('dd, MMM, yyyy')
                    : '';
            },
            renderCell: (params: GridRenderCellParams) => (
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '16px'
                    }}
                    title={params.value}
                >
                    {params.value}
                </span>
            )
        }
    ];

    function resetForm() {
        setIsDirty(false);
        reset();
    }

    const initData = async () => {
        let url = `dashboard/${user?.selectedProperty.id}?pastDays=${pastDays}&assignedToId=${id}`;
        const resp = await getRequest(url, {
        headers: {
            Authorization: `Bearer ${user!!.token}`
        }
        });
        setSummary({
            workOrder: resp.data.newWos,
            avgTimeToFinish: getTime(resp.data.avgTimeToFinish),
            callbacks: resp.data.callbacks,
            appointmentsScheduled: resp.data.apptScheduled
        });
        let url1 = `dashboard/${user?.selectedProperty.id}/graph?pastDays=${pastDays}&assignedToId=${id}`;
        const resp1 = await getRequest(url1, {
        headers: {
            Authorization: `Bearer ${user!!.token}`
        }
        });
        setGraphsData(resp1.data);
    }

    useEffect(() => {
        initData();
    }, [pastDays, id]);

    useEffect(() => {
        getGridData().then(() => setIsStarted(false));
    }, [paginationModel, valueTab, pastDays, id]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setIsDirty(getFieldState(name as any).isDirty);
            // console.log(value, name, type, formState, getFieldState(name as any));
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const updateWorkOrders = async(e: any) => {
        const wos = e.rows.filter((r: any) => e.selectedRows.indexOf(r.number) >= 0);
        switch(e.action) {
          case 'assign':
            wos.forEach(async(wo: any) => {
              await putRequest(`work-orders/${wo.other.id}`, {assignedTo: e.value.name || e.value.firstName + ' ' + e.value.lastName, assignedToPersonId: e.value.thirdPartyId}, {
                headers: {
                  Authorization: `Bearer ${user!!.token}`
                }
              });
            });
            break;
          case 'priority':
            wos.forEach(async(wo: any) => {
              await putRequest(`work-orders/${wo.other.id}`, {priority: e.value}, {
                headers: {
                  Authorization: `Bearer ${user!!.token}`
                }
              });
            });
            break;
          case 'status':
            wos.forEach(async(wo: any) => {
              await putRequest(`work-orders/${wo.other.id}`, {status: e.value}, {
                headers: {
                  Authorization: `Bearer ${user!!.token}`
                }
              });
            });
            break;
        }
        toast.success(`Update ${wos.length} work order(s) successfully`);
        setTimeout(() => {
          getGridData();
        }, 1000);
      }

    const navigate = useNavigate();

    return (
        <>
            <Box>
                <PageHeader
                    title="Tech Profile"
                    selection={<TechSelector onChange={(tech: any) => {
                        navigate(`/profile/${tech.id}`, { replace: true })
                    }} selectedTech={selectedTech} techList={user?.techUsers} />}
                    action={
                        <CustomSelect
                            value={pastDays}
                            sx={{
                                '& .MuiInputBase-input': {
                                    padding: '.675rem .75rem',
                                    background: palette.white
                                }
                            }}
                            IconComponent={ExpandMoreRoundedIcon}
                            onChange={(e: any, v: any) => {
                                setPastDays(e.target.value)
                            }}
                        >
                            <MenuItem value={1}>Past 24 Hours</MenuItem>
                            <MenuItem value={7}>Past 7 Days</MenuItem>
                            <MenuItem value={14}>Past 14 Days</MenuItem>
                            <MenuItem value={30}>Past 30 Days</MenuItem>
                            <MenuItem value={90}>Past 90 Days</MenuItem>
                        </CustomSelect>
                    }
                />
                {isMobile ? (
                    <Stack direction="row" spacing={2} sx={{ backgroundColor: palette.white, mx: -2, px: 2, mt: -4, pt: 4, mb: -2, pb: 2 }}>
                        <Box onClick={() => {
                            setDrawer({
                                open: true,
                                type: DrawerType.DateFilter
                            })
                        }}>
                            <Select
                                sx={{
                                    pointerEvents: 'none'
                                }}
                                value={pastDays}
                                input={<MobileSelect />}
                                IconComponent={ExpandMoreRoundedIcon}
                                onChange={(e: any, v: any) => {
                                    setPastDays(e.target.value)
                                }}
                            >
                                <MenuItem value={1}>Past 24 Hours</MenuItem>
                                <MenuItem value={7}>Past 7 Days</MenuItem>
                                <MenuItem value={14}>Past 14 Days</MenuItem>
                                <MenuItem value={30}>Past 30 Days</MenuItem>
                                <MenuItem value={90}>Past 90 Days</MenuItem>
                            </Select>
                        </Box>
                        <Box onClick={() => {
                            setDrawer({
                                open: true,
                                type: DrawerType.PropertyFilter
                            })
                        }}>
                            <TechSelector
                                onChange={(tech: any) => setSelectedTech(tech)} selectedTech={selectedTech} techList={[]} sx={{
                                    pointerEvents: 'none'
                                }} input={<MobileSelect />}
                                renderValue={(displayValue) => (value: any) => displayValue}
                            />
                        </Box>
                    </Stack>
                ) : null}
                <Box sx={{ boxShadow: '0px 2px 8px -8px rgba(48, 52, 54, 0.08)', mb: 10, ...(isMobile ? { backgroundColor: palette.white, mx: -2, px: 2, pb: 2 } : {}) }}>
                    <Grid container spacing={3} sx={{ mt: isMobile ? 1 : 0 }}>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <SummaryCard
                                isMobile={isMobile}
                                title={<FormattedMessage id="New Work Orders" />}
                                data={summary.workOrder!!}
                                // description="+8.4% Compared to Past 7 days"
                                icon={<NewReleasesOutlinedIcon />}
                                iconBgColor="#EDF1F3"
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <SummaryCard
                                isMobile={isMobile}
                                title={<FormattedMessage id="Avg. Time to Finish" />}
                                data={summary.avgTimeToFinish!!}
                                // description="+8.4% Compared to past 7 days"
                                icon={<TimerOutlinedIcon />}
                                iconBgColor="#CEE6FF"
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <SummaryCard
                                isMobile={isMobile}
                                title={<FormattedMessage id="Call Backs" />}
                                data={summary.callbacks!!}
                                // description="+12% Compared to past 7 days"
                                icon={<PhoneInTalkOutlinedIcon />}
                                iconBgColor="#FFF3C4"
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <SummaryCard
                                isMobile={isMobile}
                                title={<FormattedMessage id="Appt. Scheduled" />}
                                data={summary.appointmentsScheduled!!}
                                icon={<CalendarTodayOutlined />}
                                iconBgColor="#F6DBFA"
                            />
                        </Grid>
                    </Grid>
                </Box>


                <Box sx={{ mb: 10 }}>
                    <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ mb: 5 }}>
                        {<FormattedMessage id="Graphs and Charts" />}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <GraphCard title={<FormattedMessage id="Work Order Breakdown" />} sx={{ height: '100%' }}>
                                <WorkOrderBreakDownChart graphsData={graphsData}/>
                            </GraphCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GraphCard title={<FormattedMessage id="Service Type Breakdown" />} sx={{ height: '100%' }}>
                                <ServiceTypeBreakDown graphsData={graphsData} />
                            </GraphCard>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 5 }}>
                    <Typography variant="h4" sx={{ mb: 2 }}><FormattedMessage id="Work Orders" /></Typography>
                </Box>
                <Grid container>
                    {/* Desktop */}
                    {!isMobile && (
                        <>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        py: 2,
                                        background: '#FFFFFF',
                                        borderRadius: '16px',
                                        boxShadow: '0px 6px 24px 0px #3034361A'
                                    }}
                                >
                                    <Grid container>
                                        {/* Filter */}
                                        <Grid item xs={12} pb={1} px={2}>
                                            <GridFilter
                                                value={valueTab}
                                                count={total}
                                                options={statusOptions}
                                                onChange={async (val) => {
                                                    setValueTab(val);
                                                }}
                                            />
                                        </Grid>

                                        {/* Search */}
                                        <Grid item xs={12} px={2}>
                                            <GridSearch
                                                control={control}
                                                searchOptions
                                                techOptions={techOptions}
                                                priorityOptions={priorityOptions}
                                                serviceTypeOptions={serviceTypeOptions}
                                                dateOptions
                                                unitOptions={unitOptions}
                                                onFilter={onFilterSelected}
                                                selectedValues={getValues()}
                                            />
                                        </Grid>
                                        {/* Grid */}
                                        <Grid item xs={12} px={1}>
                                            <WorkOrderGrid
                                                initialState={{
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            id: false
                                                        }
                                                    }
                                                }}
                                                columns={columns}
                                                rows={rows}
                                                total={total}
                                                setPaginationModel={setPaginationModel}
                                                paginationModel={paginationModel}
                                                setSortModel={setSortModel}
                                                sortModel={sortModel}
                                                setCellModesModel={setCellModesModel}
                                                cellModesModel={cellModesModel}
                                                onCellClick={onCellClick}
                                                checkboxSelection
                                                enableRowSelectAction={true}
                                                onAction={(e: any)=>{
                                                    updateWorkOrders(e);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {/* Pagination */}
                            <Grid item xs={12} pt={2}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: 5
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography pr={2} pt={1}>
                                            <FormattedMessage id="Rows Per Page" />
                                        </Typography>
                                        <TextField
                                            select
                                            label=""
                                            defaultValue="10"
                                            size="small"
                                            onChange={(e) => {
                                                setPaginationModel({
                                                    page: 0,
                                                    pageSize: parseInt(e.target.value, 10)
                                                });
                                            }}
                                            sx={{ bgcolor: '#E8EBEE' }}
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </TextField>
                                    </Box>
                                    <Pagination
                                        page={paginationModel.page + 1}
                                        count={Math.ceil(total / paginationModel.pageSize)}
                                        shape="rounded"
                                        onChange={(e, page) =>
                                            setPaginationModel({
                                                page: page - 1,
                                                pageSize: paginationModel.pageSize
                                            })
                                        }
                                        renderItem={(item) => (
                                            <PaginationItem
                                                components={{
                                                    previous: NavigateBeforeIcon,
                                                    next: NavigateNextIcon
                                                }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </>
                    )}

                    {/* Mobile */}
                    {isMobile && (
                        <Grid item xs={12}>
                            <Grid item xs={12} py={1}>
                                <GridFilter
                                    value={valueTab}
                                    count={total}
                                    options={statusOptions}
                                    onChange={async (val) => {
                                        setValueTab(val);
                                    }}
                                />
                            </Grid>
                            {searchVisibility && (
                                <Grid item xs={12} py={1}>
                                    <GridSearch
                                        control={control}
                                        searchOptions={searchVisibility}
                                    />
                                </Grid>
                            )}

                            <Grid container spacing={2} sx={{paddingBottom: 4}}>
                                <Grid item xs={12} >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography variant="h5" fontWeight={500}>
                                            {total} Total Work Orders
                                        </Typography>

                                        {isDirty && (
                                            <Link
                                                text="Clear Filters"
                                                variant="body1"
                                                onClick={() => resetForm()}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                {rows.map((row, idx) => (
                                    <Grid key={idx} item xs={12} >
                                        <DataRowCard
                                            data={row}
                                            onClick={(type) => {
                                                if (type === 'details') {
                                                  setSelectedWo(row.other);
                                                } else if (type === 'technician') {
                                                  setSelectedWo(row.other);
                                                  setEditTechModal(true);
                                                } else if (type === 'priority') {
                                                  setSelectedWo(row.other);
                                                  setEditPriorityModal(true);
                                                }
                                                // console.log(type);
                                            }}
                                        />
                                    </Grid>
                                ))}
                                {rows.length === 0 && (
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <ActionCard title="No Rows" onClick={() => { }} />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <AppBar
                                    position="fixed"
                                    color="secondary"
                                    sx={{ top: 'auto', bottom: 0 }}
                                >
                                    <Toolbar>
                                        <IconButton
                                            color="inherit"
                                            onClick={() => setSearchVisibility(!searchVisibility)}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={() => toggleDrawer(!searchOpen)}
                                        >
                                            <FilterListIcon />
                                        </IconButton>
                                        <Box sx={{ flexGrow: 1 }} />
                                        <IconButton
                                            sx={{
                                                color: '#217345',
                                                bgcolor: '#D6F9E5',
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>
                                <SwipeableDrawerComp
                                    anchor={'bottom'}
                                    open={searchOpen}
                                    onClose={() => toggleDrawer(false)}
                                    onOpen={() => toggleDrawer(true)}
                                >
                                    <Grid container pt={2}>
                                        <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    px: 2
                                                }}
                                            >
                                                <Typography variant="h5" fontWeight={500}>
                                                    Filter By
                                                </Typography>
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => {
                                                        toggleDrawer(false);
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>
                                            <Box sx={{ border: '1px solid #EDF1F3', my: 1 }}></Box>
                                        </Grid>
                                        <Grid item xs={12} px={2}>
                                            <GridSearch
                                                control={control}
                                                techOptions={techOptions}
                                                priorityOptions={priorityOptions}
                                                serviceTypeOptions={serviceTypeOptions}
                                                dateOptions
                                                unitOptions={unitOptions}
                                                onFilter={onFilterSelected}
                                                selectedValues={getValues()}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{}}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    px: 2,
                                                    py: 2,
                                                    boxShadow: '0px 6px 24px 0px #3034361A'
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        textDecoration: 'underline',
                                                        textDecorationStyle: 'dotted',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        resetForm();
                                                    }}
                                                >
                                                    Clear all
                                                </Typography>
                                                <Box>
                                                    <PrimaryButton
                                                        btnText="Show results"
                                                        onClick={() => {
                                                            toggleDrawer(false);
                                                        }}
                                                        isSmall
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </SwipeableDrawerComp>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>
            {selectedWo && !editPriorityModal && !editTechModal && <WorkOrderDetail wo={selectedWo} onClose={() => {setSelectedWo(null); getGridData();}} />}
            {selectedWo && editPriorityModal && <EditPriorityModal wo={selectedWo} options={priorityOptions} onClose={() => {setSelectedWo(null); setEditPriorityModal(false); getGridData();}} />}
            {selectedWo && editTechModal && <EditTechModal wo={selectedWo} options={techOptions} onClose={() => {setSelectedWo(null); setEditTechModal(false); getGridData();}} />}
        </>
    );
}

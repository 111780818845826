import { IUser } from '@utils/models';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { FullStory } from '@fullstory/browser';
import { getRequest, postRequest } from '@utils/http.service';

export interface IAuthContext {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
  login: (token: string, email: any) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
  isPmRole: () => boolean;
  updateTechList: (sp: any) => void;
  updateProfile: () => void;
  updateAJobs: () => void;
  isAdminRole: () => boolean;
}

const DefaultValues: IAuthContext = {
  user: null,
  setUser: () => null,
  login: () => null,
  logout: () => null,
  isAuthenticated: () => false,
  isPmRole: () => false,
  updateTechList: (sp: any) => null,
  updateProfile: () => null,
  updateAJobs: () => null,
  isAdminRole: () => false,
};

const AuthContext = createContext<IAuthContext>(DefaultValues);

export function useAuth() {
  return useContext(AuthContext);
}

interface IProps {
  children: ReactNode;
}

export function AuthProvider({ children }: IProps) {
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    if(user?.selectedProperty?.id)
      updateUserData();
  }, [user?.selectedProperty?.id]);

  const updateUserData = async() => {
    const profileResp = await getRequest(`auth/profile`, {
      headers: {
        Authorization: `Bearer ${user?.token}`
      }
    });

    const propertiesResp = await getRequest(`properties`, {
      headers: {
        Authorization: `Bearer ${user?.token}`
      }
    });

    const usersResp = await getRequest(`users?propertyId=${user?.selectedProperty?.id}`, {
      headers: {
        Authorization: `Bearer ${user?.token}`
      }
    });

    const techUsersResp = await getRequest(`users?propertyId=${user?.selectedProperty?.id}&role=tech&role=supervisor`, {
      headers: {
        Authorization: `Bearer ${user?.token}`
      }
    });

    techUsersResp.data.forEach((d: any) => {
      d.name = d.firstName + ' ' + d.lastName.trim();
    })

    const wps = await getRequest(`work-orders/wo-tech-counts?propertyId=${user!!.selectedProperty.id}`, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });

    const categoriesResp = await getRequest(`categories`, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });

    const userData: IUser = {
      id: profileResp.data.id,
      firstName: profileResp.data.firstName,
      lastName: profileResp.data.lastName,
      email: profileResp.data.username,
      token: user!!.token,
      role: profileResp.data.roles[0].role,
      lang: profileResp.data.lang ?? 'en',
      properties: propertiesResp.data,
      users: usersResp.data,
      techUsers: techUsersResp.data,
      selectedProperty: user?.selectedProperty,
      aJobs: wps.data,
      categories: categoriesResp.data,
      thirdPartyId: profileResp.data.thirdPartyId,
    };
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  }

  const login = async (token: string, email: any) => {
    try {
      const resp = await postRequest(`auth/login`, {
        email: email,
        token: token
      });
      const profileResp = await getRequest(`auth/profile`, {
        headers: {
          Authorization: `Bearer ${resp.data.access_token}`
        }
      });

      const propertiesResp = await getRequest(`properties`, {
        headers: {
          Authorization: `Bearer ${resp.data.access_token}`
        }
      });

      const usersResp = await getRequest(`users?propertyId=${propertiesResp.data[0].id}`, {
        headers: {
          Authorization: `Bearer ${resp.data.access_token}`
        }
      });

      const techUsersResp = await getRequest(`users?propertyId=${propertiesResp.data[0].id}&role=tech&role=supervisor`, {
        headers: {
          Authorization: `Bearer ${resp.data.access_token}`
        }
      });

      techUsersResp.data.forEach((d: any) => {
        d.name = d.firstName + ' ' + d.lastName.trim();
      })

      const categoriesResp = await getRequest(`categories`, {
        headers: {
          Authorization: `Bearer ${resp.data.access_token}`
        }
      });

      const userData: IUser = {
        id: profileResp.data.id,
        firstName: profileResp.data.firstName,
        lastName: profileResp.data.lastName,
        email: email,
        token: resp.data.access_token,
        role: profileResp.data.roles[0].role,
        lang: profileResp.data.lang ?? 'en',
        properties: propertiesResp.data,
        users: usersResp.data,
        techUsers: techUsersResp.data,
        selectedProperty: propertiesResp.data[0],
        categories: categoriesResp.data,
        thirdPartyId: profileResp.data.thirdPartyId,
      };
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));

      FullStory('setIdentity', {
        uid: userData.id,
        properties: {
          email: userData.email
        }
      })

    } catch (e) {
      throw e;
    }
  };

  const isAuthenticated = (): boolean => {
    const authed = user?.email && user?.id ? true : false;
    if(authed) {
      FullStory('setIdentity', {
        uid: user!!.id,
        properties: {
          email: user!!.email
        }
      })
    }
    return authed;
  };

  const isPmRole = (): boolean => {
    return user?.email && user?.id && user.role !== 'tech' ? true : false;
  };

  const isAdminRole = (): boolean => {
    return user?.email && user?.id && user.role == 'admin' ? true : false;
  };

  const updateTechList = async(sp: any) => {
    setUser({...user!!, selectedProperty: sp});
    localStorage.setItem('user', JSON.stringify({...user!!, selectedProperty: sp}));
  }

  const updateProfile = async() => {
    await updateUserData();
  }

  const logout = async () => {
    setUser(null);
    await localStorage.removeItem('user');
    await localStorage.removeItem('redirectPath');
  };

  const updateAJobs = async() => {
    const wps = await getRequest(`work-orders/wo-tech-counts?propertyId=${user!!.selectedProperty.id}`, {
      headers: {
        Authorization: `Bearer ${user!!.token}`
      }
    });
    setUser({...user!!, aJobs: wps.data});
    localStorage.setItem('user', JSON.stringify({...user!!, aJobs: wps.data}));
  }

  const value = {
    user,
    setUser,
    login,
    isAuthenticated,
    logout,
    isPmRole,
    updateTechList,
    updateProfile,
    updateAJobs,
    isAdminRole,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

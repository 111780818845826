import { Box, Grid, Paper, Typography } from '@mui/material';
import { getInitials, getColorByText } from '@utils/helpers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface IProps {
  user?: any;
  data?: any;
}

export default function AnalyticsCard({
  user,
  data,
}: IProps) {
  const navigate = useNavigate();

  const [stat, setStat] = useState({} as any);

  useEffect(() => {
    setStat({
      id: user?.id,
      name: user?.name,
      assigned: data?.wosByTechAss[user.name]?.length ?? 0,
      completed: data?.wosByTech[user.name]?.length ?? 0,
      callback: data?.wosByTechAssCb[user.name]?.length ?? 0
    })

  }, [user, data]);

  const renderCounts = (name: string, count: number, background: string) => {
    return (
      <Grid item xs={6} sx={{ p: 1 }}>
        <Box
          sx={{ backgroundColor: { background }, p: 1, borderRadius: '4px' }}
        >
          <Typography variant="h4">{count}</Typography>
          <Typography variant="body2" color={'#5A6A72'}>
            {name}
          </Typography>
        </Box>
      </Grid>
    );
  };

  const renderNameTag = (name: string) => {
    return (
      <Box
        sx={{
          width: '48px',
          height: '48px',
          lineHeight: '48px',
          border: '1px solid #00000014',
          borderRadius: '50%',
          textAlign: 'center',
          background: getColorByText(name || ''),
          color: '#fff'
        }}
      >
        {getInitials(name)}
      </Box>
    );
  };

  return (
    <Grid
      container
      sx={{
        p: 2,
        background: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0px 6px 24px 0px #3034361A'
      }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            marginBottom: '1.5rem'
          }}
        >
          {renderNameTag(stat.name ?? '')}
          <Box ml={2} sx={{cursor: 'pointer'}}>
            <Typography variant="h5">{stat.name}</Typography>
            <Typography variant="body1" color={'#57BD83'} onClick={() => {
              navigate(`/profile/${stat.id}`);
            }}>
              <FormattedMessage id='View Tech' />
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid container>
        {renderCounts('Assigned', stat.assigned, '#CEE6FF')}
        {renderCounts('Completed', stat.completed, '#D6F9E5')}
        {renderCounts('Call Backs', stat.callback, '#FFF3C4')}
      </Grid>
    </Grid>
  );
}
